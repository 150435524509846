<template>
  <main>
    <b-navbar toggleable="sm" type="dark" variant="dark">
      <b-navbar-brand :to="{ name: 'login' }">
        <h4 class="mb-0">
          PAYPACK <small class="font-weight-light">for</small> Merchants
        </h4>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <b-button
              variant="outline-light"
              class="pl-3 pr-2 py-1 br-10"
              :href="info.documentation_link"
            >
              <span class="v-align-bottom">Documentation</span>
            </b-button>
          </b-nav-item>
          <b-nav-item>
            <b-button
              variant="light"
              class="pl-3 pr-2 py-1 br-10"
              :to="{ name: 'login' }"
            >
              <span class="v-align-bottom">Sign in</span>
              <b-icon-chevron-right scale="0.65" />
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <section class="content" style="padding: 5rem 0">
      <b-container fluid class="px-3 px-sm-5">
        <b-row
          align-h="center"
          align-v="start"
          class="overflow-hidden align-items-sm-center"
        >
          <b-col cols="auto" data-aos="zoom-in-up" data-aos-duration="1000">
            <h1 class="display-4 font-weight-normal hero-text">
              <span class="w-nowrap">Rwanda's first</span> <br />
              <span class="w-nowrap">devoloper-focused</span> <br />
              <span class="w-nowrap">payment platform.</span>
            </h1>
            <b-row
              class="mt-5 pb-1 mx-1"
              data-aos="fade-right"
              data-aos-duration="1700"
              data-aos-offset="-200"
            >
              <b-button
                :to="{ name: 'register' }"
                variant="dark"
                class="mx-1 my-2 py-1 py-md-2"
                >Register an account</b-button
              >
              <b-button
                href="tel:0781192910"
                variant="light"
                class="m-1 my-2 py-1 py-md-2"
                >Contact us</b-button
              >
            </b-row>
          </b-col>
          <b-col
            data-aos="fade-left"
            data-aos-duration="1200"
            class="d-none d-sm-block"
          >
            <b-img fluid-grow :src="require('../assets/images/payment.svg')" />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <footer class="bg-dark m-0 px-3 py-4 py-md-5 text-light">
      <b-container>
        <b-row align-h="between">
          <b-col
            cols="12"
            md="5"
            lg="5"
            class="mb-5 text-center mb-md-0 text-md-left"
          >
            <h5>PAYPACK <small>for</small> Merchants</h5>
            <p class="font-weight-light text-white-50">
              <span class="w-nowrap">Rwanda's first</span> <br />
              <span class="w-nowrap">devoloper-focused</span> <br />
              <span class="w-nowrap">payment platform.</span>
            </p>
          </b-col>
          <b-col
            cols="12"
            md="3"
            lg="3"
            class="mb-4 text-center mb-md-0 text-md-left"
          >
            <h6 class="mb-3">IMPORTANT LINKS</h6>
            <ul class="footer-links font-weight-light">
              <li>
                <b-icon-caret-right-fill />
                <b-link :to="{ name: 'login' }">Home</b-link>
              </li>
              <li>
                <b-icon-caret-right-fill />
                <b-link :href="info.documentation_link" target="_blank"
                  >Documentation</b-link
                >
              </li>
            </ul>
          </b-col>
          <b-col cols="12" md="4" lg="3" class="mb-4 text-center mb-md-0">
            <h6 class="mb-3">SOCIALS</h6>
            <b-row no-gutters align-h="center" align-v="center">
              <b-col cols="auto" class="p-1">
                <b-link
                  :href="info.twitter_link"
                  target="_blank"
                  class="text-light"
                >
                  <b-icon-twitter class="cursor-pointer" />
                </b-link>
              </b-col>
              <b-col cols="auto" class="p-1">
                <b-link
                  :href="info.instagram_link"
                  target="_blank"
                  class="text-light"
                >
                  <b-icon-instagram class="cursor-pointer" />
                </b-link>
              </b-col>
              <b-col cols="auto" class="p-1">
                <b-link
                  :href="info.github_link"
                  target="_blank"
                  class="text-light"
                >
                  <b-icon-github class="cursor-pointer" />
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </main>
</template>

<script>
import info from "../../info";
export default {
  name: "paypack-home",
  data() {
    return {
      info,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-links {
  li {
    list-style-type: none;
    opacity: 0.7;
    transition: all 250ms;

    a {
      color: var(--light);
      font-size: 15px;

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      transform: translateX(10px);
      opacity: 1;
    }
  }
}
@media only screen and (max-width: 600px) {
  .navbar-brand h4 {
    font-size: 1rem;
  }
  .hero-text {
    font-size: 1.75rem;
  }
  .navbar-toggler {
    transform: scale(0.75);
  }
}
</style>