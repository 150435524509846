<template>
  <section>
    <b-row no-gutters align-v="center" class="px-3 px-sm-2">
      <b-col class="mb-3">
        <header>
          <h5 class="mb-0 font-weight-bold">Withdrawal Requests</h5>
        </header>
      </b-col>
    </b-row>
    <b-container class="mt-4">
      <b-row class="mb-2" align-v="center" align-h="between">
        <b-col class="py-2 pr-3">
          <header class="w-max-content">
            <h6 class="mb-0">All Withdrawal requests</h6>
          </header>
        </b-col>
        <b-col class="py-2 pl-3 pl-sm-1 pr-3" cols="auto">
          <b-button
            @click="refresh"
            :disabled="state.loading"
            variant="dark"
            size="sm"
          >
            Refresh
          </b-button>
        </b-col>
      </b-row>
      <b-row no-gutters align-h="center" class="">
        <transition name="component-fade" mode="out-in">
          <Line-Loader v-if="state.loading" />
        </transition>
        <transition-group
          name="component-group-fade"
          mode="out-in"
          class="w-100"
        >
          <b-container
            class="p-5 py-0 bg-light br-5"
            key="1"
            v-if="allRequests.length < 1"
          >
            <transition-group name="component-group-fade" mode="out-in">
              <p class="text-center" key="1" v-show="state.loading">
                Loading withdrawal requests...
              </p>
              <p class="text-center" key="2" v-show="!state.loading">
                You don't have any withdrawals requests.
              </p>
            </transition-group>
          </b-container>
          <b-container key="2" v-else>
            <b-row>
              <b-table-simple
                :table-class="state.loading ? 'table-busy' : ''"
                bordered
                key="table"
                hover
                small
                responsive
              >
                <b-thead class="bg-light br-10">
                  <b-tr>
                    <b-th class="text-center">NO</b-th>
                    <b-th>Names</b-th>
                    <b-th>Amount</b-th>
                    <b-th class="text-center">Provider</b-th>
                    <b-th class="text-center">Status</b-th>
                    <b-th class="text-center">Date</b-th>
                    <b-th class="text-center"></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(request, i) in allRequests" :key="i">
                    <b-td class="text-center">{{ i + 1 }}</b-td>
                    <b-td class="text-left">
                      {{ request.names }}
                    </b-td>

                    <b-td class="text-left">{{ request.amount | money }}</b-td>
                    <b-td class="text-center text-uppercase">{{
                      request.provider
                    }}</b-td>

                    <b-td class="text-center text-capitalize">
                      <b-tag
                        :variant="statusBadgeVariantHandler(request.status)"
                        class="font-weight-bold text-capitalize ml-1"
                        no-remove
                        >{{ request.status }}
                      </b-tag>
                    </b-td>

                    <b-td class="text-center">
                      {{ request.submitted_at | date }}
                    </b-td>

                    <b-td class="text-center">
                      <b-dropdown
                        boundary="viewport"
                        variant="light"
                        right
                        no-caret
                        size="sm"
                        :disabled="state.updating.includes(request.id)"
                      >
                        <template #button-content>
                          <b-spinner
                            small
                            v-if="state.updating.includes(request.id)"
                          />

                          <b-icon-three-dots-vertical v-else />
                        </template>
                        <b-dropdown-item
                          v-if="
                            request.status != 'received' &&
                            request.status != 'approved'
                          "
                          @click="setAsReceived(request.id)"
                        >
                          Set as Received
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="request.status != 'approved'"
                          v-b-modal="'approve-modal-' + request.id"
                        >
                          Approve Transfer
                        </b-dropdown-item>
                        <b-dropdown-item
                          target="_blank"
                          :href="request.bank_slip"
                          download
                          v-if="
                            request.bank_slip && request.bank_slip != 'unset'
                          "
                        >
                          Download Bank Slip
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-modal
                        :id="'approve-modal-' + request.id"
                        :title="`APPROVE WITHDRAW`"
                        title-class="h6 mb-0 font-weight-bold mt-1"
                        header-class="border-0 shadow"
                        hide-footer
                        body-class="py-4"
                        no-close-on-backdrop
                      >
                        <b-container>
                          <b-form @submit.prevent="approveTransfer(request.id)">
                            <b-form-group label="Bank Slip">
                              <b-file required v-model="bank_slip" />
                            </b-form-group>
                            <footer class="pt-3">
                              <b-container class="px-0">
                                <b-row
                                  no-gutters
                                  align-v="center"
                                  align-h="end"
                                >
                                  <b-col cols="auto" class="pl-3">
                                    <b-button
                                      variant="light"
                                      @click="resetModal(request.id)"
                                    >
                                      Cancel
                                    </b-button>
                                  </b-col>
                                  <b-col cols="auto" class="pl-3">
                                    <b-button
                                      variant="dark"
                                      class="has-loading"
                                      :disabled="state.approving"
                                      type="submit"
                                    >
                                      <span>Submit</span>
                                      <b-container v-if="state.approving">
                                        <b-spinner small />
                                      </b-container>
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </footer>
                          </b-form>
                        </b-container>
                      </b-modal>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-row>

            <b-pagination
              class="text-dark"
              hide-goto-end-buttons
              size="sm"
              @input="pageChanged"
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRows"
              :per-page="pagination.perPage"
              pills
              align="center"
              v-if="showPagination"
            ></b-pagination>
          </b-container>
        </transition-group>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import moment from "moment";
export default {
  name: "merchant-withdrawal-requests",
  components: {
    "single-request": () => import("./single-request.vue"),
  },
  data() {
    return {
      state: { loading: false, approving: false, updating: [] },
      allRequests: [],
      bank_slip: null,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 20,
        offset: 0,
      },
    };
  },

  filters: {
    money: (num) =>
      Number(num).toLocaleString("en-US", {
        style: "currency",
        currency: "RWF",
      }),
    date: (date) => moment(date).format("DD-MMM-YYYY ... HH:mm")
  },

  computed: {
    showPagination() {
      return this.pagination.totalRows > this.pagination.perPage;
    },
  },

  mounted() {
    this.fetchRequests();
  },

  methods: {
    async fetchRequests() {
      if (this.state.loading) return;
      this.state.loading = true;

      const reqData = {
        offset: this.pagination.offset,
        limit: this.pagination.perPage,
      };

      const queryString = this.$getQueryString(reqData);
      try {
        const { data } = await this.axios(
          `withdraw/internal/list?${queryString}`
        );
        if (data && Array.isArray(data.withdrawal)) {
          this.$set(this, "allRequests", data.withdrawal);
        }
        this.$set(this.pagination, "totalRows", data.total || 0);
      } catch (error) {
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.loading = false;
        this.state.updating = [];
      }
    },

    async setAsReceived(requestId) {
      const removeRequest = (item) => item.id == requestId;
      this.state.updating.push(requestId);
      try {
        const { data } = await this.axios.put(
          `withdraw/internal/receive/${requestId}`
        );

        if (data && typeof data == "object") {
          this.allRequests = this.allRequests.map((request) => {
            if (request.id == requestId) {
              request.status = data.status;
            }
            return request;
          });
        }
      } catch (error) {
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.$set(
          this.state,
          "updating",
          this.state.updating.filter(removeRequest)
        );
      }
    },

    async approveTransfer(requestId) {
      this.state.approving = true;
      let reqData = new FormData();
      reqData.append("bank_slip", this.bank_slip);

      try {
        const { data } = await this.axios.put(
          `withdraw/internal/approve/${requestId}`,
          reqData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (typeof data == "object") {
          this.allRequests = this.allRequests.map((request) => {
            if (request.id == requestId) {
              request.status = data.status;
              request.bank_slip = data.bank_slip;
            }
            return request;
          });

          this.$bvModal.hide(`approve-modal-${requestId}`);
        }
      } catch (error) {
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.approving = false;
      }
    },

    statusBadgeVariantHandler(status) {
      switch (status) {
        case "approved":
          return "success";
        case "received":
          return "warning";
        case "pending":
          return "dark";
        default:
          return "dark";
      }
    },

    pageChanged(page) {
      const { perPage } = this.pagination;
      this.$nextTick(() => {
        this.$set(this.pagination, "offset", perPage * (page - 1));
        this.$set(this.pagination, "currentPage", page);
        this.refresh();
      });
    },

    resetPagination() {
      this.$nextTick(() => {
        this.$set(this.pagination, "totalRows", 0);
        this.$set(this.pagination, "offset", 0);
        this.$set(this.pagination, "currentPage", 1);
      });
    },

    refresh() {
      this.fetchRequests();
    },

    async resetModal(requestId) {
      this.bank_slip = null;
      this.state.upproving = false;
      requestId ? this.$bvModal.hide(`approve-modal-${requestId}`) : null;
      await this.$cancelRequests();
    },
  },
};
</script>

<style></style>
