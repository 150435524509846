<template>
  <aside id="sidebar" class="pt-3 px-2">
    <ul class="side-menu">
      <b-link
        active-class="active"
        :to="{ name: 'admin-status' }"
        class="text-decoration-none my-2"
      >
        <b-icon-house-door-fill class="mr-2" />
        Dashboard
      </b-link>

      <b-link
        active-class="active"
        :to="{ name: 'admin-summaries' }"
        class="text-decoration-none my-2"
      >
        <b-icon-bar-chart-fill class="mr-2" />
        Summary
      </b-link>

      <b-link
        active-class="active"
        :to="{ name: 'admin-clients' }"
        class="text-decoration-none my-2"
      >
        <b-icon-people-fill class="mr-2" />
        Clients
      </b-link>

      <b-link
        active-class="active"
        :to="{ name: 'admin-balances' }"
        class="text-decoration-none my-2"
      >
        <b-icon-calculator-fill class="mr-2" />
        Balances
      </b-link>

      <b-link
        active-class="active"
        :to="{ name: 'admin-events' }"
        class="text-decoration-none my-2"
      >
        <b-icon-clock-fill class="mr-2" />
        Events
      </b-link>

      <b-link
        active-class="active"
        :to="{ name: 'admin-withdraw' }"
        class="text-decoration-none my-2"
      >
        <b-icon-capslock-fill class="mr-2" />
        Withdraw
      </b-link>
      <b-link
        active-class="active"
        :to="{ name: 'admin-transfers' }"
        class="text-decoration-none my-2"
      >
        <b-icon-currency-exchange class="mr-2" />
        Transfers
      </b-link>
      <b-link
        active-class="active"
        :to="{ name: 'admin-verifications' }"
        class="text-decoration-none my-2"
      >
        <b-icon-patch-check-fill class="mr-2" />
        Verification
      </b-link>

      <b-link
        active-class="active"
        :to="{ name: 'admin-webhooks' }"
        class="text-decoration-none my-2"
      >
        <b-icon-bezier2 class="mr-2" />
        Webhooks
      </b-link>
    </ul>
  </aside>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/sidebar_variable";
#sidebar {
  flex: 1;
  min-width: $sb-lg-width;
  max-width: $sb-lg-width;

  .side-menu {
    list-style: none;

    a {
      padding: 0.5rem 0.75rem;
      border-radius: 10px;
      font-weight: 600;
      cursor: pointer;
      transition: all 250ms;
      margin: 5px 0;
      display: block;
      color: var(--gray-dark);

      &:hover,
      &.active {
        background-color: var(--white);
        color: var(--dark);
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  #sidebar {
    width: $sb-sm-width !important;
  }
}
</style>
