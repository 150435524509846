<template>
  <main id="recover-password">
    <small-header />
    <section id="content-container" class="py-5">
      <b-container fluid class="px-1 px-sm-2 px-md-4 px-lg-5">
        <b-row
          no-gutters
          align-h="center"
          align-v="center"
          class="justify-content-md-around align-items-md-start flex-column flex-md-row flex-nowrap"
        >
          <b-col cols="4" md="4" lg="4" class="mb-4">
            <div class="image px-4 pt-2 br-10 bg-light">
              <b-img
                alt="forgot password image"
                fluid
                :src="require('../../assets/images/forgot-password.svg')"
              />
            </div>
          </b-col>
          <b-col cols="auto">
            <b-container
              class="text-center text-md-left"
              fluid
              id="password-reset-section"
            >
              <b-row
                no-gutters
                class="mb-2 justify-content-md-start"
                align-h="center"
              >
                <header>
                  <h1 class="h2">Forgot your Password?</h1>
                </header>
              </b-row>
              <b-row
                no-gutters
                class="mb-4 justify-content-md-start"
                align-h="center"
              >
                <article class="mb-2">
                  We are here to help you recover it.
                </article>
                <article>
                  Enter the email associated with your account and we'll send an
                  email with instructions to reset your password.
                </article>
              </b-row>
              <b-row no-gutters>
                <b-form
                  class="d-flex w-100 flex-wrap"
                  @submit.prevent="initializePasswordReset"
                >
                  <b-form-group class="flex-grow-1 mx-1">
                    <b-input
                      type="email"
                      required
                      trim
                      v-model="email"
                      placeholder="Please enter your email address"
                    />
                  </b-form-group>
                  <b-form-group class="mx-1">
                    <b-button
                      type="submit"
                      variant="dark"
                      pill
                      class="has-loading px-4"
                      :disabled="state.loading"
                    >
                      <b-container v-if="state.loading">
                        <b-spinner small />
                      </b-container>
                      <span>Send</span>
                    </b-button>
                  </b-form-group>
                </b-form>
                <b-row no-gutters class="mx-1">
                  <b-link
                    class="font-weight-bold"
                    :to="{ name: 'login' }"
                    style="font-size: 14px"
                    >Go back to Login</b-link
                  >
                </b-row>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
export default {
  components: {
    "small-header": () => import("../../components/navbars/small-header.vue"),
  },
  data() {
    return {
      state: { loading: false },
      email: null,
    };
  },
  methods: {
    initializePasswordReset() {
      if (!this.email) return;
      this.state.loading = true;
      this.state.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#password-reset-section {
  max-width: 500px;
}
#content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
  margin-top: 20px;
}
@media (min-width: 768px) {
  #content-container {
    margin-top: 60px;
  }
}
</style>