<template>
  <b-navbar class="shadow-sm" variant="dark" type="dark">
    <b-container>
      <b-row no-gutters align-h="center" align-v="center" class="w-100">
        <b-navbar-brand :to="{ name: 'login' }" class="font-weight-bold">
          PAYPACK
          <small class="font-weight-lighter">for</small>
          ADMINS
        </b-navbar-brand>
      </b-row>
    </b-container>
  </b-navbar>
</template>

<script>
export default {};
</script>

<style>
</style>