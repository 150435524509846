import moment from "moment";

export default (Transactions) => {
  const formatDate = (date) => {
    if (!moment.isDate(new Date(date))) return date;
    return moment(new Date(date)).format("DD-MM-YYYY");
  };
  const headers = [
    "Date",
    "Transactions",
    "Cash-in",
    "Cash-out",
    "Total",
    "Paypack Fees",
    "Withdrawals",
  ];
  let totals = ["Total", 0, 0, 0, 0, 0, 0];

  Transactions = Transactions.map((trx) => {
    totals[1] += trx.total;
    totals[2] += trx.cashin_amount;
    totals[3] += trx.cashout_amount;
    totals[4] += trx.total_amount;
    totals[5] += trx.total_amount * 0.007;
    totals[6] += trx.withdrawals;
    return [
      formatDate(trx.date),
      Number(trx.total).toLocaleString('en-US'),
      Number(trx.cashin_amount).toLocaleString('en-US') + " Rwf",
      Number(trx.cashout_amount).toLocaleString('en-US') + " Rwf",
      Number(trx.total_amount).toLocaleString('en-US') + " Rwf",
      Number(trx.total_amount * 0.007).toLocaleString('en-US') + " Rwf",
      Number(trx.withdrawals).toLocaleString('en-US') + " Rwf",
    ];
  });
  totals = totals.map((tot, i) => {
    if (i != 0 && i == 1) {
      tot = Number(tot).toLocaleString('en-US');
    } else if (i != 0 && i !== 1) {
      tot = Number(tot).toLocaleString('en-US') + " Rwf";
    }
    return tot;
  });

  return {
    headers,
    data: Transactions,
    footer: totals,
  };
};
