<template>
  <div class="w-100 d-flex flex-column" id="logout">
    <!-- <small-header /> -->
    <b-row
      no-gutters
      align-h="center"
      align-v="center"
      class="flex-grow-1 w-100"
    >
      <b-spinner variant="dark" />
    </b-row>
  </div>
</template>
<script>
import { smallHeader } from "../index";
export default {
  name: "logout",
  components: { smallHeader },
  async mounted() {
    try {
      await this.axios.get("auth/logout");
    } catch (error) {
      throw error;
    } finally {
      this.$store.dispatch("CLEAR_DATA");
      sessionStorage.removeItem("access");
      sessionStorage.removeItem("refresh");
      window.location.reload();
    }
  },
};
</script>

<style lang="scss" scoped>
#logout {
  min-height: 100vh;
}
</style>
