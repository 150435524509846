import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import {
  QuicksandRegular,
  QuicksandBold,
} from "../assets/fonts/Quicksand-regular.ttf.Base64.encodede.ts";

export const generatePDF = (
  merchant = "Merchant",
  headers = [],
  data = [],
  footer = [],
  dates = "",
  centeredColumns = [],
  columnWidths = Array(headers.length).fill("*")
) => {
  pdfMake.vfs["Quicksand"] = QuicksandRegular;
  pdfMake.vfs["QuicksandBold"] = QuicksandBold;

  pdfMake.fonts = {
    Roboto: {
      normal: "Roboto-Regular.ttf",
      bold: "Roboto-Medium.ttf",
      italics: "Roboto-Italic.ttf",
      bolditalics: "Roboto-MediumItalic.ttf",
    },
    Quicksand: {
      normal: "Quicksand",
      bold: "QuicksandBold",
      italics: "Quicksand",
      bolditalics: "Quicksand",
    },
  };

  const centerHelper = function(i, h) {
    return centeredColumns.includes(i + 1) ? true : false;
  };

  var dd = {
    header: {
      margin: [30, 30],
      columns: [
        { text: merchant, bold: true, fontSize: 15 },
        { text: dates, alignment: "right", fontSize: 12 },
      ],
    },
    footer: function(currentPage, pageCount) {
      return {
        columns: [
          { text: currentPage.toString() + " of " + pageCount, fontSize: 11 },
          {
            text: "Powered By PAYPACK",
            alignment: "right",
            bold: true,
            fontSize: 11,
          },
        ],
        margin: [30, 30],
      };
    },
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [30, 65, 30, 80],
    content: [
      {
        table: {
          headerRows: 1,
          widths: columnWidths,
          body: [
            headers.map((h, i) => {
              if (typeof h != "string") return h;
              return {
                text: h,
                style: "tableHeader",
              };
            }),
            ...data.map((t) => {
              return t.map((a, i) => {
                if (typeof a != "string") return a;
                return {
                  text: a,
                  style: centerHelper(i)
                    ? "centeredTableDataText"
                    : "tableDataText",
                };
              });
            }),
            footer
              ? footer.map((f, i) => {
                  if (typeof f != "string") return h;
                  return {
                    text: f,
                    style: centerHelper(i, "h")
                      ? "centeredTableFooter"
                      : "tableFooter",
                  };
                })
              : null,
          ].filter((i) => i),
        },
        layout: {
          vLineColor: () => "#e0e7f3",
          hLineColor: () => "#e0e7f3",
        },
      },
    ],
    styles: {
      tableHeader: {
        fontSize: 11,
        color: "black",
        fillColor: "#f2f5fa",
        noWrap: true,
        bold: true,
        alignment: "center",
      },
      tableFooter: {
        fontSize: 11,
        color: "black",
        fillColor: "#f2f5fa",
        noWrap: true,
        bold: true,
        alignment: "right",
      },
      centeredTableFooter: {
        fontSize: 11,
        bold: true,
        color: "black",
        fillColor: "#f2f5fa",
        noWrap: true,
        alignment: "center",
      },
      tableDataText: {
        noWrap: true,
        alignment: "right",
      },
      centeredTableDataText: {
        noWrap: true,
        alignment: "center",
      },
    },
    defaultStyle: {
      fontSize: 10,
      font: "Quicksand",
    },
  };

  // annnnnnd generate that PDF!!
  pdfMake.createPdf(dd).open();
};
