import layout from "./adminLayout.vue";

import {
  Balances,
  cashIn,
  cashOut,
  Clients,
  Events,
  Home,
  SingleRequest,
  Summary,
  Transactions,
  Transfers,
  Verifications,
  Webhooks,
  Withdraw,
} from "./pages";
export default [
  {
    path: "/admin",
    component: layout,
    children: [
      {
        path: "",
        redirect: { name: "admin-status" },
      },
      {
        path: "status",
        name: "admin-status",
        component: Home,
        meta: {
          requireAuth: true,
          title: "Status | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin status - Paypack.",
            },
            {
              property: "og:description",
              content: "admin status - Paypack.",
            },
          ],
        },
      },
      {
        path: "balances",
        name: "admin-balances",
        component: Balances,
        meta: {
          requireAuth: true,
          title: "Balances | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "Admin Balances - Paypack.",
            },
            {
              property: "og:description",
              content: "Admin Balances - Paypack.",
            },
          ],
        },
      },
      {
        path: "summaries",
        name: "admin-summaries",
        component: Summary,
        meta: {
          requireAuth: true,
          title: "Summary | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "Admin Summary - Paypack.",
            },
            {
              property: "og:description",
              content: "Admin Summary - Paypack.",
            },
          ],
        },
      },
      {
        path: "transactions",
        name: "admin-transactions",
        component: Transactions,
        meta: {
          requireAuth: true,
          title: "Transactions | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin transactions - Paypack.",
            },
            {
              property: "og:description",
              content: "admin transactions - Paypack.",
            },
          ],
        },
      },
      {
        path: "cash-out",
        name: "admin-cash-out",
        component: cashOut,
        meta: {
          requireAuth: true,
          title: "Cash out | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin cash out - Paypack.",
            },
            {
              property: "og:description",
              content: "admin cash out - Paypack.",
            },
          ],
        },
      },
      {
        path: "cash-in",
        name: "admin-cash-in",
        component: cashIn,
        meta: {
          requireAuth: true,
          title: "Cash in | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin cash in - Paypack.",
            },
            {
              property: "og:description",
              content: "admin cash in - Paypack.",
            },
          ],
        },
      },
      {
        path: "events",
        name: "admin-events",
        component: Events,
        meta: {
          requireAuth: true,
          title: "Events | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin Events - Paypack.",
            },
            {
              property: "og:description",
              content: "admin Events - Paypack.",
            },
          ],
        },
      },
      {
        path: "clients",
        name: "admin-clients",
        component: Clients,
        meta: {
          requireAuth: true,
          title: "Clients | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin clients - Paypack.",
            },
            {
              property: "og:description",
              content: "admin clients - Paypack.",
            },
          ],
        },
      },
      {
        path: "Verifications",
        name: "admin-verifications",
        component: Verifications,
        meta: {
          requireAuth: true,
          title: "Verification Requests | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin Verification Requests - Paypack.",
            },
            {
              property: "og:description",
              content: "admin Verification Requests - Paypack.",
            },
          ],
        },
      },
      {
        path: "verification/:id",
        name: "admin-single-verification",
        component: SingleRequest,
        props: true,
        meta: {
          requireAuth: true,
          title: "Verification request | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin verification request - Paypack.",
            },
            {
              property: "og:description",
              content: "admin verification request - Paypack.",
            },
          ],
        },
      },
      {
        path: "withdraw",
        name: "admin-withdraw",
        component: Withdraw,
        props: true,
        meta: {
          requireAuth: true,
          title: "Withdraw Request | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin withdraw request - Paypack.",
            },
            {
              property: "og:description",
              content: "admin withdraw request - Paypack.",
            },
          ],
        },
      },
      {
        path: "webhooks",
        name: "admin-webhooks",
        component: Webhooks,
        props: true,
        meta: {
          requireAuth: true,
          title: "webhooks Request | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin webhooks request - Paypack.",
            },
            {
              property: "og:description",
              content: "admin webhooks request - Paypack.",
            },
          ],
        },
      },
      {
        path: "transfers",
        name: "admin-transfers",
        component: Transfers,
        props: true,
        meta: {
          requireAuth: true,
          title: "transfers Request | Paypack Atlas",
          metaTags: [
            {
              name: "description",
              content: "admin transfers request - Paypack.",
            },
            {
              property: "og:description",
              content: "admin transfers request - Paypack.",
            },
          ],
        },
      },
    ],
  },
];
