<script>
import { Bar } from "vue-chartjs";
import { format } from "d3-format";
export default {
  extends: Bar,
  props: {
    chartData: {
      type: Array,
      required: false,
    },
    chartLabels: {
      type: Array,
      required: true,
    },
    beforeTooltip: {
      type: String,
      required: false,
    },
    afterTooltip: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                display: true,
                callback: function (val, index) {
                  return format(".2s")(val);
                },
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        bezierCurve: false,
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return Number(
                Number(tooltipItem.yLabel).toFixed(2)
              ).toLocaleString('en-US');
            },
          },
        },
      },
    };
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: [...this.chartLabels],
        datasets: [
          {
            borderColor: "#23477c",
            borderWidth: 2,
            pointBorderColor: "#23477c",
            pointRadius: 1,
            pointBackgroundColor: "#23477c",
            backgroundColor: "#23477c",
            label: "Data",
            stepped: "middle",
            fill: false,
            tension: 0.3,
            minBarLength: 2,
            maxBarThickness: 50,
            data: [...this.chartData],
          },
        ],
      },
      this.options
    );
  },
};
</script>

<style></style>
