<template>
  <main id="login" class="vh-100 d-flex flex-column">
    <small-header />
    <section
      class="py-5 h-100 d-flex flex-column align-items-center justify-content-center"
    >
      <b-container>
        <b-row no-gutters align-h="center" align-v="center" class="mt-2">
          <h2 class="d-block">ADMIN LOGIN</h2>
        </b-row>
        <b-row no-gutters align-h="center" align-v="center" class="mt-2">
          <h5>
            Login <span class="d-none d-sm-inline">your Paypack</span> admin
            account
          </h5>
        </b-row>

        <b-row
          class="mt-4 mt-md-5"
          no-gutters
          align-v="center"
          align-h="center"
        >
          <b-col cols="10" sm="7" md="6" lg="5" xl="4">
            <b-form class="w-100" @submit.prevent="loginHandler">
              <b-form-group label-class="font-weight-bold" label="Email">
                <b-input-group>
                  <b-input
                    v-model="email"
                    class="border border-secondary"
                    trim
                    required
                    autofocus
                  />
                  <b-input-group-append is-text>
                    @paypack.rw
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group label-class=" font-weight-bold" label="Password">
                <b-input
                  v-model="password"
                  type="password"
                  required
                  class="border border-secondary"
                />
                <!-- <legend class="forgot text-right">
                  <b-link
                    class="font-weight-bold"
                    :to="{ name: 'forgot-password' }"
                    >Forgot your password?</b-link
                  >
                </legend> -->
              </b-form-group>
              <b-form-group class="pt-3">
                <b-button
                  type="submit"
                  variant="dark"
                  class="w-100 has-loading"
                  :disabled="state.loading"
                >
                  <b-container v-if="state.loading">
                    <b-spinner />
                  </b-container>
                  <span>Log in</span>
                </b-button>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
import { smallHeader } from "../../components/index";
export default {
  name: "login-page",
  components: { smallHeader },
  data() {
    return {
      email: null,
      password: null,
      state: { loading: false, errors: [] },
    };
  },
  methods: {
    async loginHandler() {
      this.state.loading = true;
      const reqData = {
        email: this.email + "@paypack.rw",
        password: this.password,
      };
      try {
        const { data } = await this.axios.post("auth/users/login", reqData);
        if (data.access) {
          sessionStorage.setItem("access", data.access);
          sessionStorage.setItem("refresh", data.refresh);
          this.$router.replace("/admin");
        }
      } catch (e) {
        if (e.errorMessage) {
          if (Array.isArray(e.errorMessage))
            this.$notify(e.errorMessage[0], "error");
          else this.$notify(e.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      }
      this.state.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot a {
  white-space: nowrap;
  font-size: 14px;
}
</style>
