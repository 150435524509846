<template>
  <div class="verification-container p-0 overflow-hidden">
    <b-link
      :to="{
        name: 'admin-single-verification',
        params: { id: request.id },
      }"
      class="text-body text-decoration-none cursor-pointer"
    >
      <b-row
        class="verification border-bottom"
        :class="[String(request.status).toLowerCase()]"
        align-v="center"
      >
        <b-col>
          <h6
            class="mb-0 font-weight-bold d-flex align-items-start justify-content-between"
          >
            {{ request.name || request.merchant_id }}
            <b-tag
              :variant="statusBadgeVariantHandler(request.status)"
              class="font-weight-bold text-capitalize ml-1"
              no-remove
            >
              {{ request.status }}
            </b-tag>
          </h6>
          <small class="d-block">
            <b>Merchant ID: {{ request.merchant_id }}</b>
          </small>
          <small class="d-block">
            {{ request.created_at | date }}
          </small>
        </b-col>
      </b-row>
    </b-link>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["request"],
  filters: {
    money: (num) => Number(num).toLocaleString('en-US'),
    date: (date) => {
      if (!moment.isDate(moment(date).toDate())) return "";
      return moment(date).format("MMMM Do, YYYY");
    },
    fullDate: (date) => {
      if (!moment.isDate(moment(date).toDate())) return "";
      return moment(date).format("MMMM Do YYYY, hh:mm A");
    },
  },
  methods: {
    statusBadgeVariantHandler(status) {
      switch (status) {
        case "approved":
          return "success";
        case "rejected":
          return "danger";
        case "pending":
          return "warning";
        default:
          return "warning";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.verification-container {
  background: #d3def0;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%), 0 0 0 1px rgb(0 0 0 / 5%);
  position: relative;
  cursor: pointer;
  transition: all 250ms ease;
  margin-right: -15px;
  margin-left: -15px;
  border-radius: 5px;
  margin-bottom: 15px;

  &:hover {
    filter: brightness(0.9);
  }

  .verification {
    position: relative;
    padding: 10px 15px 5px;

    &.approved::before {
      background: var(--success);
    }
    &.pending::before {
      background: var(--secondary);
    }
    &.rejected::before {
      background: var(--danger);
    }
  }
  .more-details {
    legend {
      font-size: 15px;
      font-weight: 800;
      margin-bottom: 3px;
    }
    p {
      font-size: 15px;
      margin-bottom: 0px;
    }
  }
}
</style>
