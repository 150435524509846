<template>
  <section>
    <b-container class="mt-4">
      <b-row class="mb-2" align-v="end" align-h="end">
        <b-col class="py-2 pr-3">
          <header class="my-2">
            <h5 class="mb-0 font-weight-bold">Balances</h5>
          </header>
        </b-col>
        <b-col class="py-2 pl-3 pl-sm-1 pr-3" cols="auto">
          <b-row class="align-items-end justify-content-end mx-0">
            <div class="m-1">
              <transition name="component-fade" mode="out-in">
                <b-skeleton variant="light" width="100px" class="mb-0 h-100" v-if="state.balanceLoading"></b-skeleton>
                <b-row v-else align-h="end" align-v="end" class="mx-0"><b-skeleton variant="light" width="100px"
                    class="mb-0 h-100" v-if="state.airtelBalanceLoading"></b-skeleton>
                  <div v-else class="w-max-content w-nowrap bg-light rounded px-3 py-1 m-1">
                    <span class="small d-block">Airtel Funds</span>
                    <p class="mb-0 w-nowrap w-max-content">
                      RWF
                      <span class="font-15 font-weight-bold">
                        {{ airtelBalance | currency }}
                      </span>
                    </p>
                  </div>
                  <div class="w-max-content w-nowrap bg-light rounded px-3 py-1 m-1">
                    <span class="small d-block">Actual Funds</span>
                    <p class="mb-0 w-nowrap w-max-content">
                      RWF
                      <span class="font-15 font-weight-bold">
                        {{ fdiBalance.actual_funds | currency }}
                      </span>
                    </p>
                  </div>
                  <div class="w-max-content w-nowrap bg-light rounded px-3 py-1 m-1">
                    <span class="small">Available Funds</span>
                    <p class="mb-0 w-nowrap w-max-content">
                      RWF
                      <span class="font-15 font-weight-bold">
                        {{ fdiBalance.available_funds | currency }}
                      </span>
                    </p>
                  </div>
                </b-row>
              </transition>
            </div>
            <b-button class="my-2 mx-2" @click="refresh" :disabled="state.loading" variant="dark" size="sm">
              Refresh
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <b-row no-gutters align-h="center" class="">
        <transition name="component-fade" mode="out-in">
          <Line-Loader v-if="state.loading" />
        </transition>
        <transition-group name="component-group-fade" mode="out-in" class="w-100">
          <b-container class="p-5 py-0 bg-light br-5" key="1" v-if="allClients.length < 1">
            <transition-group name="component-group-fade" mode="out-in">
              <p class="text-center" key="1" v-show="state.loading">
                Loading balances...
              </p>
              <p class="text-center" key="2" v-show="!state.loading">
                You don't have any clients, at the moment.
              </p>
            </transition-group>
          </b-container>
          <b-container key="2" v-else>
            <b-row>
              <b-table-simple :table-class="state.loading ? 'table-busy' : ''" key="table" hover small responsive
                bordered>
                <b-thead class="bg-light br-10">
                  <b-tr>
                    <b-th class="pl-3">No</b-th>
                    <b-th class="pl-3">Client ID</b-th>
                    <b-th colspan="5" class="pl-3">Client Name</b-th>
                    <b-th colspan="3" class="text-right pr-4">MTN Balance</b-th>
                    <b-th colspan="3" class="text-right pr-4">AIRTEL Balance</b-th>
                    <b-th colspan="3" class="text-right pr-4">AIRTEL CASHOUT Balance</b-th>
                    <b-th colspan="3" class="text-right pr-4">Balance</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(client, i) in allClients" :key="i">
                    <b-td class="pl-3">
                      {{ i + 1 }}
                    </b-td>
                    <b-td class="pl-3">
                      {{ client.id }}
                    </b-td>
                    <b-td colspan="5" class="pl-3">
                      {{ client.name }}
                    </b-td>
                    <b-td colspan="3" class="text-right pr-4">
                      {{ client.mtn_balance | currency }} Rwf
                    </b-td>
                    <b-td colspan="3" class="text-right pr-4">
                      {{ client.airtel_balance | currency }} Rwf
                    </b-td>
                    <b-td colspan="3" class="text-right pr-4">
                      {{ client.airtel_cashout_balance | currency }} Rwf
                    </b-td>
                    <b-td colspan="3" class="text-right pr-4">
                      {{ client.balance | currency }} Rwf
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-row>
            <b-pagination size="sm" @input="pageChanged" v-model="pagination.currentPage"
              :total-rows="pagination.totalRows" :per-page="pagination.perPage" pills align="center"
              v-if="showPagination"></b-pagination>
          </b-container>
        </transition-group>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import _orderBy from "lodash/orderBy";
export default {
  name: "merchant-clients",
  data() {
    return {
      state: {
        loading: false,
        balanceLoading: false,
        airtelBalanceLoading: false,
        deleting: [],
      },
      allClients: [],
      fdiBalance: 0,
      airtelBalance: 0,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 50,
        offset: 0,
      },
    };
  },
  filters: {
    currency: (num) => Number(num).toLocaleString('en-US'),
  },
  computed: {
    showPagination() {
      return this.pagination.totalRows > this.pagination.perPage;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async fetchClientsBalances() {
      this.allClients = [];
      if (this.state.loading) return;
      this.state.loading = true;
      const reqData = {
        offset: this.pagination.offset,
        limit: this.pagination.perPage,
      };
      try {
        const { data } = await this.axios(
          `internal/balances/list?${this.$getQueryString(reqData)}`
        );
        if (Array.isArray(data.balances)) {
          this.$set(this.pagination, "totalRows", data.total);
          this.$set(
            this,
            "allClients",
            _orderBy(
              data.balances,
              [
                "data.balances",
                function (o) {
                  return o.balance;
                },
              ],
              ["desc", "desc"],
              null,
              4
            )
          );
        }
      } catch (e) {
        if (e.errorMessage) {
          if (Array.isArray(e.errorMessage))
            this.$notify(e.errorMessage[0], "error");
          else this.$notify(e.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.loading = false;
      }
    },
    async fetchFDIBalance() {
      if (this.state.balanceLoading) return;
      this.state.balanceLoading = true;
      try {
        const { data } = await this.axios("internal/balances/paypack-fdi");
        if (Array.isArray(data.currencies)) {
          this.fdiBalance = data.currencies[0];
        }
      } catch (e) {
        if (e.errorMessage) {
          if (Array.isArray(e.errorMessage))
            this.$notify(e.errorMessage[0], "error");
          else this.$notify(e.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.balanceLoading = false;
      }
    },
    async fetchAirtelBalance() {
      if (this.state.airtelBalanceLoading) return;
      this.state.airtelBalanceLoading = true;
      try {
        const { data } = await this.axios("internal/balances/paypack-airtel");
        if (data.balance) {
          this.airtelBalance = data.balance;
        }
      } catch (error) {
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.airtelBalanceLoading = false;
      }
    },
    refresh() {
      this.fetchFDIBalance();
      this.fetchAirtelBalance();
      this.fetchClientsBalances();
    },
    pageChanged(page) {
      const { perPage } = this.pagination;
      this.$nextTick(() => {
        this.$set(this.pagination, "offset", perPage * (page - 1));
        this.$set(this.pagination, "currentPage", page);
        this.fetchClientsBalances();
      });
    },
    resetPage() {
      this.$nextTick(() => {
        this.$set(this.filters.dates, "from", null);
        this.$set(this.filters.dates, "to", null);
        this.$set(this.filters, "client", null);
        this.$set(this, "totals", null);
        this.resetPagination();
      });
    },
    resetPagination() {
      this.$nextTick(() => {
        this.$set(this.pagination, "totalRows", 0);
        this.$set(this.pagination, "offset", 0);
        this.$set(this.pagination, "currentPage", 1);
      });
    },
  },
};
</script>

<style></style>
