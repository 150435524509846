<template>
  <section>
    <header>
      <h5 class="mb-0 font-weight-bold">Webhooks</h5>
    </header>
    <b-container class="mt-4">
      <b-row class="mb-2" align-v="center">
        <b-col class="py-2 pr-3">
          <header>
            <h6 class="mb-0">All Webhooks</h6>
          </header>
        </b-col>
        <b-col class="py-2 pl-3 pl-sm-1 pr-3" cols="auto">
          <b-button
            @click="refresh"
            :disabled="state.loading"
            variant="dark"
            size="sm"
          >
            Refresh
          </b-button>
        </b-col>
      </b-row>
      <b-row no-gutters align-h="center" class="">
        <transition name="component-fade" mode="out-in">
          <Line-Loader v-if="state.loading" />
        </transition>
        <transition-group
          name="component-group-fade"
          mode="out-in"
          class="w-100"
        >
          <b-container
            class="p-5 py-0 bg-light br-5"
            key="1"
            v-if="allWebhooks.length < 1"
          >
            <transition-group name="component-group-fade" mode="out-in">
              <p class="text-center" key="1" v-show="state.loading">
                Loading webhooks...
              </p>
              <p class="text-center" key="2" v-show="!state.loading">
                You don't have any webhooks.
              </p>
            </transition-group>
          </b-container>
          <b-container key="2" v-else>
            <b-row>
              <b-table-simple
                :table-class="state.loading ? 'table-busy' : ''"
                key="table"
                hover
                small
                bordered
                responsive
              >
                <b-thead class="bg-light br-10">
                  <b-tr>
                    <b-th class="text-center">NO</b-th>
                    <b-th class="text-center">Merchant</b-th>
                    <b-th>Webhook URL</b-th>
                    <b-th>Mode</b-th>
                    <b-th class="text-center">Status</b-th>
                    <b-th class="text-center">Last Called</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(webhook, i) in allWebhooks" :key="i">
                    <b-td class="text-center">{{ i + 1 }}</b-td>
                    <b-td class="text-center">{{ webhook.merchant }}</b-td>
                    <b-td
                      style="max-width: 50px"
                      class="text-truncate"
                      :title="webhook.url"
                    >
                      {{ webhook.url }}
                    </b-td>
                    <b-td>{{ webhook.mode | capitalized }}</b-td>
                    <b-td class="">
                      <b-icon-circle-fill
                        scale="0.45"
                        font-scale="0.9"
                        :variant="webhook.active ? 'success' : 'danger'"
                      />
                      {{ webhook.active ? "Activated" : "Deactivated" }}
                    </b-td>
                    <b-td class="text-center">
                      {{ webhook.last_used | date }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-row>
            <b-pagination
              size="sm"
              @input="pageChanged"
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRows"
              :per-page="pagination.perPage"
              pills
              align="center"
              v-if="showPagination"
            ></b-pagination>
          </b-container>
        </transition-group>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import moment from "moment";
export default {
  name: "admin-webhooks",
  data() {
    return {
      state: { loading: false },
      allWebhooks: [],
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 30,
        offset: 0,
      },
    };
  },
  filters: {
    date: (date) => moment(date).format("MMMM Do, YYYY"),
    capitalized: (value) =>
      String(value).charAt(0).toUpperCase() + value.slice(1),
  },
  computed: {
    showPagination() {
      return this.pagination.totalRows > this.pagination.perPage;
    },
  },
  mounted() {
    this.fetchWebhooks();
  },
  methods: {
    async fetchWebhooks() {
      if (this.state.loading) return;
      this.state.loading = true;
      try {
        const reqData = {
          offset: this.pagination.offset,
          limit: this.pagination.perPage,
        };
        const queryString = this.$getQueryString(reqData);
        const { data } = await this.axios(
          `/internal/webhooks/list?${queryString}`
        );
        if (Array.isArray(data.hooks)) {
          this.$set(this, "allWebhooks", data.hooks);
          this.$set(this.pagination, "totalRows", data.total);
        }
      } catch (error) {
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      }
      this.state.loading = false;
    },
    pageChanged(page) {
      const { perPage } = this.pagination;
      this.$nextTick(() => {
        this.$set(this.pagination, "offset", perPage * (page - 1));
        this.$set(this.pagination, "currentPage", page);
        this.refresh();
      });
    },
    resetPagination() {
      this.$nextTick(() => {
        this.$set(this.pagination, "totalRows", 0);
        this.$set(this.pagination, "offset", 0);
        this.$set(this.pagination, "currentPage", 1);
      });
    },
    refresh() {
      this.fetchWebhooks();
    },
  },
};
</script>

<style></style>
