<template>
  <main id="signup">
    <small-header />
    <section class="py-5">
      <b-container>
        <b-row no-gutters align-h="center" align-v="center" class="mt-2">
          <h3>
            Create <span class="d-none d-sm-inline">your Paypack</span> account
          </h3>
        </b-row>
        <b-row
          class="mt-4 mt-md-5"
          no-gutters
          align-v="center"
          align-h="center"
        >
          <b-col cols="10" sm="7" md="6" lg="5" xl="4">
            <b-form class="w-100" @submit.prevent="registerHandler">
              <b-form-group label-class="font-weight-bold" label="Name">
                <b-input v-model="name" class="border border-secondary" trim />
              </b-form-group>
              <b-form-group label-class="font-weight-bold" label="Email">
                <b-input
                  v-model="email"
                  type="email"
                  class="border border-secondary"
                  trim
                />
              </b-form-group>
              <!-- <b-form-group label-class="font-weight-bold" label="Phone number">
                <b-input
                  number
                  v-model="number"
                  class="border border-secondary"
                  trim
                />
              </b-form-group> -->
              <b-form-group label-class=" font-weight-bold" label="Password">
                <b-input
                  type="password"
                  v-model="password"
                  class="border border-secondary"
                />
              </b-form-group>
              <b-form-group
                label-class="font-weight-bold"
                label="Confirm password"
              >
                <b-input
                  type="password"
                  v-model="confirmPassword"
                  class="border border-secondary"
                />
              </b-form-group>
              <b-form-group class="pt-3">
                <b-button
                  type="submit"
                  variant="dark"
                  class="w-100 has-loading"
                  :disabled="state.loading"
                >
                  <b-container v-if="state.loading">
                    <b-spinner />
                  </b-container>
                  <span>Register</span>
                </b-button>
              </b-form-group>
              <b-form-group class="pt-3">
                <p class="text-center font-weight-normal">
                  Already have an account?
                  <b-link
                    class="text-decoration-none font-weight-bold"
                    :to="{ name: 'login' }"
                    >Sign in</b-link
                  >
                </p>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
import { smallHeader } from "../../components/index";
export default {
  name: "signup-page",
  components: { smallHeader },
  data() {
    return {
      name: null,
      email: null,
      password: null,
      number: null,
      confirmPassword: null,
      state: { loading: false },
    };
  },
  methods: {
    async registerHandler() {
      this.state.loading = true;
      const reqData = {
        name: this.name,
        email: this.email,
        password: this.password,
      };
      try {
        const { data } = await this.axios.post("merchants", reqData);
        if (data.name)
          this.$notify(`${data.name} is registered successfully!`, "success");
        this.$router.replace({ name: "login" });
      } catch (e) {
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      }
      this.state.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
