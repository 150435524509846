<template>
  <section>
    <b-container class="mt-4">
      <b-row class="mb-2" align-v="center">
        <b-col class="py-2 pr-3">
          <header>
            <h5 class="mb-0 font-weight-bold">Summary</h5>
          </header>
        </b-col>
        <b-col class="py-2 pl-3 pl-sm-1 pr-3" cols="auto">
          <b-button
            @click="refresh"
            :disabled="state.loading"
            variant="dark"
            size="sm"
          >
            Refresh
          </b-button>
        </b-col>
      </b-row>
      <b-row no-gutters align-h="center" class="">
        <transition name="component-fade" mode="out-in">
          <Line-Loader v-if="state.loading" />
        </transition>
        <transition-group
          name="component-group-fade"
          mode="out-in"
          class="w-100"
        >
          <b-container
            class="p-5 py-0 bg-light br-5"
            key="1"
            v-if="allSummary.length < 1"
          >
            <transition-group name="component-group-fade" mode="out-in">
              <p class="text-center" key="1" v-show="state.loading">
                Loading Summary...
              </p>
              <p class="text-center" key="2" v-show="!state.loading">
                Summary details not found.
              </p>
            </transition-group>
          </b-container>
          <b-container key="2" v-else>
            <b-row>
              <b-table-simple
                :table-class="state.loading ? 'table-busy' : ''"
                key="table"
                hover
                small
                responsive
                bordered
              >
                <b-thead class="bg-light br-10">
                  <b-tr>
                    <b-th class="text-center">NO</b-th>
                    <b-th colspan="4">Client Name</b-th>
                    <b-th class="text-center px-2">Cash-in</b-th>
                    <b-th class="text-center px-2">Cash-out</b-th>
                    <b-th class="text-center px-2">Total</b-th>
                    <b-th class="text-center px-2">Withdrawals</b-th>
                    <b-th class="text-center px-2">2.3%</b-th>
                    <b-th class="text-center px-2">0.7%</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(summary, i) in allSummary" :key="i">
                    <b-td class="text-center">
                      {{ i + 1 }}
                    </b-td>
                    <b-td colspan="4">
                      <b-link v-b-modal="summary.id">{{ summary.name }}</b-link>
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ summary.cashin | currency }} Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ summary.cashout | currency }} Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ (summary.cashin + summary.cashout) | currency }} Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ summary.withdrawals | currency }} Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{
                        (((summary.cashin + summary.cashout) * 2.3) / 100)
                          | currency
                      }}
                      Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{
                        (((summary.cashin + summary.cashout) * 0.7) / 100)
                          | currency
                      }}
                      Rwf
                    </b-td>
                    <b-modal
                      :id="summary.id"
                      :title="`${summary.name} Daily Transactions`"
                      header-class="border-0 shadow"
                      size="xl"
                      scrollable
                      hide-footer
                      centered
                      no-close-on-backdrop
                      content-class="h-100 w-95vw"
                    >
                      <daily-tranx :merchant="summary" />
                    </b-modal>
                  </b-tr>
                  <!-- totals -->
                  <b-tr variant="secondary">
                    <b-td></b-td>
                    <b-td colspan="4" class="font-weight-bold text-center"
                      >TOTAL:
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ totals.cashin | currency }} Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ totals.cashout | currency }} Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ totals.total | currency }} Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ totals.withdrawals | currency }} Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ ((totals.total * 2.3) / 100) | currency }}
                      Rwf
                    </b-td>
                    <b-td class="text-right px-2">
                      {{ ((totals.total * 0.7) / 100) | currency }}
                      Rwf
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-row>
            <b-pagination
              size="sm"
              @input="pageChanged"
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRows"
              :per-page="pagination.perPage"
              pills
              align="center"
              v-if="showPagination"
            ></b-pagination>
          </b-container>
        </transition-group>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import _orderBy from "lodash/orderBy";
import dailyTranx from "./daily-tranx.vue";
export default {
  components: { dailyTranx },
  name: "merchant-clients",
  data() {
    return {
      state: { loading: false, deleting: [] },
      allSummary: [],
      totals: { cashin: 0, cashout: 0, total: 0, withdrawals: 0 },
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 30,
        offset: 0,
      },
    };
  },
  filters: {
    currency: (num) => Number(Number(num).toFixed()).toLocaleString("en-US"),
  },
  computed: {
    showPagination() {
      return this.pagination.totalRows > this.pagination.perPage;
    },
  },
  mounted() {
    this.fetchSummaries();
  },
  methods: {
    async fetchSummaries() {
      if (this.state.loading) return;
      this.state.loading = true;
      try {
        const reqData = {
          offset: this.pagination.offset,
          limit: this.pagination.perPage,
        };
        const queryString = this.$getQueryString(reqData);
        const { data } = await this.axios(
          `internal/reports/summary?${queryString}`
        );
        if (Array.isArray(data.transactions)) {
          this.totals = {
            cashin: 0,
            cashout: 0,
            total: 0,
            withdrawals: 0,
          };
          this.$set(this.pagination, "totalRows", data.total);
          Object.keys(data.transactions).forEach((i) => {
            this.totals.cashin += data.transactions[i].cashin;
            this.totals.cashout += data.transactions[i].cashout;
            this.totals.total +=
              data.transactions[i].cashin + data.transactions[i].cashout;
            this.totals.withdrawals += data.transactions[i].withdrawals;
          });
          this.$set(
            this,
            "allSummary",
            _orderBy(
              data.transactions,
              [
                "data.transactions",
                function (o) {
                  return o.cashin + o.cashout;
                },
              ],
              ["desc", "desc"],
              null,
              4
            )
          );
        }
      } catch (error) {
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.loading = false;
      }
    },
    pageChanged(page) {
      const { perPage } = this.pagination;
      this.$nextTick(() => {
        this.$set(this.pagination, "offset", perPage * (page - 1));
        this.$set(this.pagination, "currentPage", page);
        this.refresh();
      });
    },
    resetPagination() {
      this.$nextTick(() => {
        this.$set(this.pagination, "totalRows", 0);
        this.$set(this.pagination, "offset", 0);
        this.$set(this.pagination, "currentPage", 1);
      });
    },
    refresh() {
      this.fetchSummaries();
    },
  },
};
</script>

<style lang="scss">
.w-95vw {
  width: 95vw !important;
}
</style>
