<template>
  <b-container>
    <b-form @submit.prevent="filter">
      <b-row
        no-gutters
        @reset="resetForm"
        align-v="center"
        align-h="end"
        class="mb-2 border-bottom pb-1"
      >
        <b-col>
          <h6 class="mb-0 font-weight-bold">Filter Transactions</h6>
        </b-col>
        <b-col cols="auto">
          <b-button
            @click="resetForm"
            size="sm"
            class="py-0 px-1"
            variant="light"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
      <b-form-group label="From">
        <b-form-datepicker
          hide-header
          v-model="from"
          :max="today"
          required
          :date-format-options="{
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }"
        />
      </b-form-group>
      <b-form-group label="To">
        <b-form-datepicker
          hide-header
          v-model="to"
          :min="from"
          :max="today"
          required
          :date-format-options="{
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }"
        />
      </b-form-group>

      <b-row align-h="end" align-v="end" class="pt-2">
        <b-button class="mx-3 py-1" variant="light" @click="cancel">
          cancel
        </b-button>
        <b-button class="mr-3 py-1" variant="dark" type="submit">
          Filter
        </b-button>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import moment from "moment";
import { isPhoneNumber } from "rwa-validator";
export default {
  props: ["filters", "modalID", "defaultDates"],
  data() {
    return {
      from: null,
      to: null,
    };
  },
  computed: {
    today() {
      return moment().toDate();
    },
  },
  beforeMount() {
    if (this.filters && this.filters.dates) {
      this.$nextTick(() => {
        this.$set(this, "from", this.filters.dates.from);
        this.$set(this, "to", this.filters.dates.to);
      });
    }
  },
  watch: {
    to() {
      if (moment(this.to).isBefore(this.from)) {
        this.$nextTick(() => {
          this.$set(this, "to", null);
        });
      }
    },
    from() {
      if (moment(this.to).isBefore(this.from)) {
        this.$nextTick(() => {
          this.$set(this, "to", null);
        });
      }
    },
  },
  methods: {
    filter() {
      this.$emit("filtered", {
        from: this.from,
        to: this.to,
      });
      this.$bvModal.hide("transactions-filters");
      this.$bvModal.hide(this.modalID);
    },
    cancel() {
      this.$bvModal.hide("transactions-filters");
      this.$bvModal.hide(this.modalID);
      this.resetForm();
    },
    resetForm() {
      this.$nextTick(() => {
        if (this.defaultDates) {
          this.$set(this, "from", this.defaultDates.from);
        } else {
          this.$set(
            this,
            "from",
            moment().subtract(1, "months").format("YYYY-MM-DD")
          );
        }

        this.$set(this, "to", moment().format("YYYY-MM-DD"));
      });
    },
  },
};
</script>

<style>
</style>