<template>
  <b-container class="">
    <b-row>
      <b-row align-h="end" align-v="start" class="w-100" no-gutters>
        <b-col cols="12" sm="auto">
          <h5 class="mb-0 font-weight-bold m-1">
            <b-row no-gutters class="flex-nowrap mb-1" align-v="center">
              <span>Clients</span>
              <b-form-select v-model="state.clientType" size="sm" class="ml-2">
                <b-select-option value="all">All</b-select-option>
                <b-select-option value="new">New</b-select-option>
              </b-form-select>
            </b-row>
          </h5>
          <small class="small font-weight-bold m-1"
            >Today: {{ today | readableDate }}</small
          >
        </b-col>
        <b-col>
          <b-form class="justify-content-end" inline @submit.prevent="refresh">
            <b-form-group class="m-1" v-if="!selectedNew">
              <b-form-datepicker
                class="m-1"
                size="sm"
                placeholder="From"
                hide-header
                v-model="filters.dates.from"
                :max="today"
                :date-format-options="{
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                }"
              />
            </b-form-group>
            <b-form-group class="m-1">
              <b-form-datepicker
                class="m-1"
                size="sm"
                placeholder="Select date"
                hide-header
                v-model="filters.dates.to"
                :max="today"
                required
                :date-format-options="{
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                }"
              />
            </b-form-group>
            <b-row class="flex-nowrap" no-gutters>
              <b-button
                block
                class="m-1"
                type="submit"
                variant="dark"
                size="sm"
                :disabled="state.loading"
              >
                Refresh
              </b-button>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <transition name="component-fade" mode="out-in">
        <Line-Loader v-if="state.loading" />
      </transition>
      <transition-group name="component-group-fade" mode="out-in" class="w-100">
        <b-container
          class="p-5 py-0 bg-light br-5"
          key="1"
          v-if="allTransactions.length < 1"
        >
          <transition-group name="component-group-fade" mode="out-in">
            <p class="text-center" key="1" v-show="state.loading">
              Loading Clients...
            </p>
            <p class="text-center" key="2" v-show="!state.loading">
              Clients not found.
            </p>
          </transition-group>
        </b-container>
        <b-container key="2" v-else>
          <b-row align-h="center">
            <b-table-simple
              :table-class="state.loading ? 'table-busy' : ''"
              key="table"
              hover
              small
              responsive
              bordered
            >
              <b-thead class="br-10">
                <b-tr variant="light">
                  <b-th class="text-center">No</b-th>
                  <b-th class="text-center">Client</b-th>
                  <b-th class="text-center">Transactions</b-th>
                  <b-th class="text-center">Cash-in</b-th>
                  <b-th class="text-center px-2">Cash-out</b-th>
                  <b-th class="text-center px-2">Total</b-th>
                </b-tr>
              </b-thead>
              <b-tbody style="font-size: 15px">
                <b-tr v-for="(tranx, i) in allTransactions" :key="i">
                  <b-td class="text-center">
                    {{ i + 1 }}
                  </b-td>
                  <b-td class="text-center">
                    {{ tranx.client }}
                  </b-td>
                  <b-td class="text-center px-2">
                    {{ tranx.transactions }}
                  </b-td>
                  <b-td class="text-right px-2">
                    {{ tranx.cashin | currency }} Rwf
                  </b-td>
                  <b-td class="text-right px-2">
                    {{ tranx.cashout | currency }} Rwf
                  </b-td>
                  <b-td class="text-right px-2">
                    {{ tranx.total | currency }} Rwf
                  </b-td>
                </b-tr>
                <!-- totals -->
                <b-tr variant="secondary font-weight-bold">
                  <b-td class="text-center"></b-td>
                  <b-td class="text-center">TOTAL:</b-td>
                  <b-td class="text-center">{{ totals.transactions }} </b-td>
                  <b-td class="text-right px-2">
                    {{ totals.cashin | currency }} Rwf
                  </b-td>
                  <b-td class="text-right px-2">
                    {{ totals.cashout | currency }} Rwf
                  </b-td>
                  <b-td class="text-right px-2">
                    {{ totals.total | currency }} Rwf
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-pagination
              size="sm"
              @input="pageChanged"
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRows"
              :per-page="pagination.perPage"
              pills
              align="center"
              v-if="showPagination"
            ></b-pagination>
          </b-row>
        </b-container>
      </transition-group>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import processTrx from "../../../../helpers/transactionsProccessor";
import { generatePDF } from "../../../../helpers/pdfGenerator";
export default {
  data() {
    let dates = {
      from: moment().subtract(1, "day").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
    };
    return {
      state: { loading: false, clientType: "all" },
      from: dates.from,
      to: dates.to,
      allTransactions: [],
      totals: {
        transactions: 0,
        cashin: 0,
        cashout: 0,
        total: 0,
      },
      filters: {
        client: null,
        dates,
      },
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 50,
        offset: 0,
      },
    };
  },
  computed: {
    today() {
      return moment().toDate();
    },
    showPagination() {
      return this.pagination.totalRows > this.pagination.perPage;
    },
    selectedNew() {
      return this.state.clientType == "new" ? true : false;
    },
  },
  watch: {
    "state.clientType"() {
      this.fetchTransactions();
    },
  },
  filters: {
    date: (date) => {
      if (!moment.isDate(new Date(date))) return date;
      return moment(new Date(date)).format("DD-MM-YYYY");
    },
    readableDate: (date) => {
      if (!moment.isDate(new Date(date))) return date;
      return moment(new Date(date)).format("Do MMMM YYYY");
    },
    currency: (num) => Number(num).toLocaleString('en-US'),
  },
  mounted() {
    this.fetchTransactions();
  },
  methods: {
    async fetchTransactions() {
      if (this.state.loading) return;
      this.state.loading = true;
      try {
        const startOfDay = (date) =>
          moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
        const endOfDay = (date) =>
          moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
        const reqData = {
          from: this.selectedNew ? null : startOfDay(this.filters.dates.from),
          to: endOfDay(this.filters.dates.to),
          offset: this.pagination.offset,
          limit: this.pagination.perPage,
        };
        const queryString = this.$getQueryString(reqData);
        const { data } = await this.axios(
          `internal/reports/clients/${this.state.clientType}?${queryString}`
        );
        if (Array.isArray(data.transactions)) {
          this.totals = {
            transactions: 0,
            cashin: 0,
            cashout: 0,
            total: 0,
          };
          Object.keys(data.transactions).forEach((i) => {
            this.totals.transactions += data.transactions[i].transactions;
            this.totals.cashin += data.transactions[i].cashin;
            this.totals.cashout += data.transactions[i].cashout;
            this.totals.total += data.transactions[i].total;
          });
          this.$set(this.pagination, "totalRows", data.total);
          this.$set(this, "allTransactions", data.transactions);
        }
      } catch (error) {
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.loading = false;
      }
    },
    refresh() {
      this.fetchTransactions();
    },
    pageChanged(page) {
      const { perPage } = this.pagination;
      this.$nextTick(() => {
        this.$set(this.pagination, "offset", perPage * (page - 1));
        this.$set(this.pagination, "currentPage", page);
        this.refresh();
      });
    },
    resetPage() {
      this.$nextTick(() => {
        this.$set(this.filters.dates, "from", null);
        this.$set(this.filters.dates, "to", null);
        this.$set(this, "totals", null);
        this.resetPagination();
      });
    },
    resetPagination() {
      this.$nextTick(() => {
        this.$set(this.pagination, "totalRows", 0);
        this.$set(this.pagination, "offset", 0);
        this.$set(this.pagination, "currentPage", 1);
      });
    },
    async openPDF() {
      return;
      const dates = `${this.filters.dates.from} / ${this.filters.dates.to}`;
      const proccessedTransactions = await processTrx(this.allTransactions);
      const centeredColumns = [1, 2];

      let { headers, data, footer } = proccessedTransactions;
      generatePDF(
        `New clients -${this.today}`,
        headers,
        data,
        footer,
        dates,
        centeredColumns
      );
    },
  },
};
</script>

<style></style>
