<template>
  <div fluid class="event-container my-3 p-0 rounded overflow-hidden">
    <b-row
      class="event border-bottom"
      :class="[String(event.data.status).toLowerCase()]"
      align-v="center"
      v-b-toggle="event.event_id"
    >
      <b-col>
        <h6 class="mb-0 font-weight-bold cursor-text">
          {{ event.event_kind }}
          <b-tag variant="light" class="font-weight-bold ml-1" no-remove>{{
            event.data.kind
          }}</b-tag>
        </h6>
        <small class="cursor-text">{{ event.data.ref }}</small>
        <small class="cursor-text d-block mt-1">
          <span class="font-weight-bold">CLIENT:</span>
          {{ event.data.client }}
        </small>
      </b-col>
    </b-row>
    <b-collapse :id="event.event_id">
      <b-row no-gutters class="p-3 more-details">
        <b-col class="px-2 pb-3" cols="12" md="6">
          <legend>Client</legend>
          <p class="cursor-text">{{ event.data.client }}</p>
        </b-col>
        <b-col class="px-2 pb-3" cols="12" md="6">
          <legend>Amount</legend>
          <p>{{ event.data.amount | money }} Rwf</p>
        </b-col>
        <b-col class="px-2 pb-3" cols="12" md="6">
          <legend>Kind</legend>
          <p>{{ event.data.kind }}</p>
        </b-col>
        <b-col class="px-2 pb-3" cols="12" md="6">
          <legend>Status</legend>
          <p>{{ event.data.status }}</p>
        </b-col>
        <b-col class="px-2 pb-3" cols="12" md="6">
          <legend>Merchant</legend>
          <p class="cursor-text">{{ event.data.merchant }}</p>
        </b-col>
        <b-col class="px-2 pb-3" cols="12" md="6">
          <legend>Transaction Ref</legend>
          <p class="cursor-text">{{ event.data.ref }}</p>
        </b-col>
        <b-col class="px-2 pb-3" cols="12" md="6">
          <legend>Event ID</legend>
          <p class="cursor-text">{{ event.event_id }}</p>
        </b-col>
        <b-col class="px-2 pb-3" cols="12" md="6">
          <legend>Created at</legend>
          <p class="cursor-text">{{ event.data.created_at | fullDate }}</p>
        </b-col>
        <b-col class="px-2 pb-3" cols="12" md="6">
          <legend>Processed at</legend>
          <p class="cursor-text">{{ event.data.processed_at | fullDate }}</p>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["event"],
  filters: {
    money: (num) => Number(num).toLocaleString('en-US'),
    date: (date) => {
      if (!moment.isDate(moment(date).toDate())) return "";
      return moment(date).format("DD-MM-YY, H:mm");
    },
    fullDate: (date) => {
      if (!moment.isDate(moment(date).toDate())) return "";
      return moment(date).format("MMMM Do YYYY, H:mm:ss");
    },
  },
};
</script>

<style lang="scss" scoped>
.event-container {
  background: #d1dbec4d;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%), 0 0 0 1px rgb(0 0 0 / 5%);
  position: relative;
  cursor: pointer;
  transition: all 250ms ease;
  margin-right: -15px;
  margin-left: -15px;

  &:hover {
    filter: brightness(0.9);
  }

  .event {
    position: relative;
    padding: 10px 15px 16px;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 8px;
      margin-left: -1px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background: var(--gray);
      top: 0;
    }

    &.successful::before {
      background: var(--success);
    }
    &.pending::before {
      background: var(--gray);
    }
    &.failed::before {
      background: var(--danger);
    }
  }
  .more-details {
    legend {
      font-size: 15px;
      font-weight: 800;
      margin-bottom: 3px;
    }
    p {
      font-size: 15px;
      margin-bottom: 0px;
    }
  }
}
</style>