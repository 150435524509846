import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import metaHandler from "../helpers/metaHandler";
import RouteGuard from "../helpers/route-guard";

import { Login, forgotPassword } from "../pages";
import { Logout } from "../components";

import adminRoutes from "../dashboards/admin/adminRoutes";

Vue.use(VueRouter);

const guestRoutes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "login" },
    meta: {
      guest: true,
      title: "Home - Paypack",
      metaTags: [
        {
          name: "description",
          content: "Paypack home.",
        },
        {
          property: "og:description",
          content: "Paypack home.",
        },
      ],
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      guest: true,
      title: "Login - Paypack",
      metaTags: [
        {
          name: "description",
          content: "Paypack payment api account Login.",
        },
        {
          property: "og:description",
          content: "Paypack payment api account Login.",
        },
      ],
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: forgotPassword,
    meta: {
      guest: true,
      title: "Password reset - Paypack",
      metaTags: [
        {
          name: "description",
          content: "Paypack payment account reset password.",
        },
        {
          property: "og:description",
          content: "Paypack payment account reset password.",
        },
      ],
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      helper: true,
      title: "Paypack",
      metaTags: [
        {
          name: "description",
          content: "Paypack payment api.",
        },
        {
          property: "og:description",
          content: "Paypack payment api account.",
        },
      ],
    },
  },
];

const routes = [...guestRoutes, ...adminRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  store.dispatch("CANCEL_PENDING_REQUESTS");
  metaHandler(to, from, next);
  RouteGuard(to, from, next);
});

export default router;
