<template>
  <b-container fluid class="px-0">
    <b-row
      align-h="end"
      class="font-15 text-uppercase border-top totals-box pt-2"
    >
      <b-col cols="auto" class="px-0">
        <b-row
          no-gutters
          class="my-2 flex-nowrap"
          align-h="between"
          v-if="cashinTotal"
        >
          <b-col cols="auto" class="text-right pr-5">CASHIN</b-col>
          <b-col class="flex-grow-1">
            <span class="text-right d-block w-nowrap"
              >{{ cashinTotal | money }} RWF</span
            >
          </b-col>
        </b-row>
        <b-row
          no-gutters
          class="my-2 flex-nowrap"
          align-h="between"
          v-if="cashoutTotal"
        >
          <b-col cols="auto" class="text-right pr-5">CASHOUT</b-col>
          <b-col class="flex-grow-1">
            <span class="text-right d-block w-nowrap">
              <span v-if="showNegatives">-</span> {{ cashoutTotal | money }} RWF
            </span>
          </b-col>
        </b-row>
        <b-row
          no-gutters
          class="my-2 flex-nowrap"
          align-h="between"
          v-if="amountTotal"
        >
          <b-col cols="auto" class="text-right pr-5">Amount</b-col>
          <b-col class="flex-grow-1">
            <span class="text-right d-block w-nowrap">
              <span v-if="showNegatives">-</span> {{ amountTotal | money }} RWF
            </span>
          </b-col>
        </b-row>
        <b-row
          no-gutters
          class="my-2 flex-nowrap"
          align-h="between"
          v-if="feeHandler"
        >
          <b-col cols="auto" class="text-right pr-5">FEE</b-col>
          <b-col class="flex-grow-1">
            <span class="text-right d-block w-nowrap">
              <span v-if="showNegatives">-</span> {{ feeHandler | money }} RWF
            </span>
          </b-col>
        </b-row>
        <b-row
          no-gutters
          class="my-2 flex-nowrap border-top pt-2"
          align-h="between"
        >
          <b-col cols="auto" class="text-right pr-5"> BALANCE</b-col>
          <b-col class="flex-grow-1">
            <span class="text-right d-block w-nowrap"
              >{{ totalHandler | money }} RWF</span
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: ["totals"],
  computed: {
    cashinTotal() {
      return this.totals.cashin;
    },
    cashoutTotal() {
      return this.totals.cashout;
    },
    amountTotal() {
      return this.totals.amount;
    },
    feeHandler() {
      return this.totals.fee;
    },
    totalHandler() {
      return this.totals.total;
    },
    showNegatives() {
      return this.cashinTotal > 0;
    },
  },
  filters: {
    money: (num) => Number(num).toLocaleString('en-US'),
  },
};
</script>

<style lang="scss" scoped>
.totals-box {
  min-width: max-content;
  font-weight: 600;
}
.info-btn {
  position: absolute;
  right: 100%;
  top: -2px;
}
.tooltip {
  top: 0;
}
</style>