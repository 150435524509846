<template>
  <b-row no-gutters class="flex-nowrap" align-v="end">
    <b-col>
      <transition name="component-fade" mode="out-in">
        <p
          class="mb-0 w-nowrap bg-primary br-5 text-dark px-3 py-1 w-max-content"
          v-if="balance"
        >
          RWF
          <span class="font-15 font-weight-bold">{{ balance | currency }}</span>
        </p>
        <b-skeleton
          variant="primary"
          height="24px"
          width="100px"
          class="mb-0"
          v-else
        ></b-skeleton>
      </transition>
    </b-col>
  </b-row>
</template>

<script>
export default {
  computed: {
    balance() {
      return this.$store.state.balance;
    },
  },
  filters: {
    currency: (number) => Number(number).toLocaleString('en-US'),
  },
  created() {
    this.$store.dispatch("REFRESH_PROFILE");
  },
};
</script>

<style>
</style>