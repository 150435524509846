<template>
  <section>
    <b-row no-gutters align-v="center" class="px-3 px-sm-2">
      <b-col class="mb-3">
        <header>
          <h5 class="mb-0 font-weight-bold">Verification Requests</h5>
        </header>
      </b-col>
    </b-row>
    <b-container class="">
      <b-row no-gutters class="" align-v="center" align-h="between">
        <b-col class="py-2 pr-3">
          <header class="w-max-content">
            <h6 class="mb-0">All Verification requests</h6>
            <label class="small font-weight-bold mb-0">
              {{ filters.dates.from | date }} / {{ filters.dates.to | date }}
            </label>
          </header>
        </b-col>
        <b-col cols="12" sm="auto">
          <b-button
            @click="refresh"
            :disabled="state.loading"
            variant="dark"
            size="sm"
            class="m-1"
          >
            Refresh
          </b-button>
        </b-col>
      </b-row>
      <b-row no-gutters align-h="center">
        <transition name="component-fade" mode="out-in">
          <Line-Loader v-if="state.loading" />
        </transition>
        <transition-group
          name="component-group-fade"
          mode="out-in"
          class="w-100"
        >
          <b-container
            class="p-5 py-0 bg-light br-5"
            key="1"
            v-if="shownRequests.length < 1"
          >
            <transition-group name="component-group-fade" mode="out-in">
              <p class="text-center" key="1" v-show="state.loading">
                Loading verification requests...
              </p>
              <p class="text-center" key="2" v-show="!state.loading">
                {{
                  state.message ||
                  "There are no verification requests at the moment."
                }}
              </p>
            </transition-group>
          </b-container>
          <b-container key="2" v-else>
            <verification-card
              v-for="(request, i) in shownRequests"
              :request="request"
              :key="i"
            />
            <b-pagination
              size="sm"
              @input="pageChanged"
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRows"
              :per-page="pagination.perPage"
              pills
              align="center"
              v-if="showPagination"
            ></b-pagination>
          </b-container>
        </transition-group>
      </b-row>
    </b-container>
    <b-modal
      size="sm"
      hide-header
      body-class="px-1"
      id="events-filters"
      centered
      header-bg-variant="light"
      hide-footer
      no-close-on-backdrop
      width="400px"
    >
      <events-filters
        @filtered="filterEvents"
        :filters="filters"
        modalID="events-filters"
        :defaultDates="defaultDates"
      />
    </b-modal>
  </section>
</template>

<script>
import _filter from "lodash/filter";
import _orderBy from "lodash/orderBy";
import moment from "moment";
import { balancePill, transactionsFilters } from "../../components";
import verificationCard from "./verification-card";
export default {
  name: "merchant-events",
  components: {
    balancePill,
    verificationCard,
    "events-filters": transactionsFilters,
  },
  data() {
    let dates = {
      from: moment().subtract(1, "days").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
    };
    return {
      state: { loading: false, message: null, fetchingMerchants: false },
      allRequests: [],
      selectedKind: null,
      selectedStatus: null,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 100,
        offset: 0,
      },
      filters: {
        kind: null,
        status: null,
        client: null,
        ref: null,
        merchant: "",
        dates,
      },
      allMerchants: [],
    };
  },
  mounted() {
    this.fetchRequests();
    // this.fetchMerchants();
  },
  filters: {
    date: (date) => {
      if (!moment.isDate(new Date(date))) return date;
      return moment(new Date(date)).format("DD-MM-YYYY");
    },
  },
  computed: {
    shownRequests() {
      return _orderBy(this.allRequests, ["created_at"], ["desc"]);
    },
    showPagination() {
      return this.pagination.totalRows > this.pagination.perPage;
    },
    today() {
      return moment();
    },
    defaultDates() {
      return {
        from: moment().subtract(1, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      };
    },
  },
  watch: {
    "filters.kind"() {
      this.$nextTick(() => {
        this.$set(this, "allEvents", []);
        this.resetPagination();
        this.refresh();
      });
    },
    "filters.status"() {
      this.$nextTick(() => {
        this.$set(this, "allEvents", []);
        this.resetPagination();
        this.refresh();
      });
    },
    "filters.ref"() {
      if (!this.filters.ref) return;
      this.$nextTick(() => {
        this.$set(this.filters, "client", "");
      });
    },
    "filters.client"() {
      if (!this.filters.client) return;
      this.$nextTick(() => {
        this.$set(this.filters, "ref", "");
      });
    },
  },
  methods: {
    async fetchRequests() {
      if (this.state.loading) return;
      this.state.message = null;
      this.state.loading = true;
      const { from, to } = this.filters.dates;
      const startOfDay = (date) =>
        moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
      const endOfDay = (date) =>
        moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
      const reqData = {
        offset: this.pagination.offset,
        limit: this.pagination.perPage,
        kind: this.filters.kind,
        client: this.filters.client,
        status: this.filters.status,
        ref: this.filters.ref,
        merchant: this.filters.merchant,
        // from: startOfDay(this.filters.dates.from),
        // to: endOfDay(this.filters.dates.to),
      };
      const queryString = this.$getQueryString(reqData);
      try {
        const { data } = await this.axios(
          `internal/account-verification/list?${queryString}`
        );
        if (data && Array.isArray(data.verifications)) {
          if (data.verifications.length < 1) {
            const fd = (date) => moment(date).format("MMMM, DD YYYY");
            this.state.message = `Found no ${
              this.filters.status ? this.filters.status : ""
            } verification requests from ${fd(from)} to ${fd(to)}`;
          }
          this.$set(this.pagination, "totalRows", data.total);
          this.$set(this, "allRequests", data.verifications);
        }
      } catch (error) {
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      }
      this.state.loading = false;
    },
    async fetchMerchants() {
      this.state.fetchingMerchants = true;

      try {
        const { data } = await this.axios.get("internal/merchants/list");
        const hiddenIDS = ["AAAAAA", "BBBBBB", "CCCCCC", "DDDDDD"];
        this.$set(
          this,
          "allMerchants",
          data.merchants
            .filter((item) => !hiddenIDS.includes(item.id))
            .filter((i) => i.balance > 0)
            .sort((a, b) => a.name.localeCompare(b.name))
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.state.fetchingMerchants = false;
      }
    },
    refresh() {
      this.fetchRequests();
    },
    filterEvents(params) {
      this.$nextTick(() => {
        this.$set(this.filters.dates, "from", params.from);
        this.$set(this.filters.dates, "to", params.to);
        this.resetPagination();
        this.refresh();
      });
    },
    pageChanged(page) {
      const { perPage } = this.pagination;
      this.$nextTick(() => {
        this.$set(this.pagination, "offset", perPage * (page - 1));
        this.$set(this.pagination, "currentPage", page);
        this.refresh();
      });
    },
    resetPage() {
      this.$nextTick(() => {
        this.$set(this.filters.dates, "from", null);
        this.$set(this.filters.dates, "to", null);
        this.$set(this.filters, "client", null);
        this.$set(this, "totals", null);
        this.resetPagination();
      });
    },
    resetPagination() {
      this.$nextTick(() => {
        this.$set(this.pagination, "totalRows", 0);
        this.$set(this.pagination, "offset", 0);
        this.$set(this.pagination, "currentPage", 1);
      });
    },
  },
};
</script>

<style></style>
