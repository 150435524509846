<template>
  <section>
    <b-row no-gutters align-v="center" class="px-3 px-sm-2">
      <b-col class="mb-3">
        <header>
          <h5 class="mb-0 font-weight-bold">Transactions</h5>
        </header>
      </b-col>
      <b-col cols="auto" class="mb-3">
        <balance-pill />
      </b-col>
    </b-row>
    <b-container class="mt-3">
      <!-- <b-row
        no-gutters
        align-h="start"
        class="border border-light p-1 my-3 br-5"
      >
        <b-col cols="auto">
          <b-button-toolbar>
            <b-dropdown size="sm" variant="light" class="m-1" right>
              <template #button-content>
                <span class="m-1"
                  >KIND: {{ selectedKind ? selectedKind : "All" }}</span
                >
              </template>
              <b-dropdown-item @click="selectedKind = null"
                >All</b-dropdown-item
              >
              <b-dropdown-item @click="selectedKind = 'CASHIN'"
                >CASH IN</b-dropdown-item
              >
              <b-dropdown-item @click="selectedKind = 'CASHOUT'"
                >CASH OUT</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown size="sm" variant="light" class="m-1" right>
              <template #button-content>
                <span class="m-1"
                  >STATUS:
                  {{ selectedStatus ? selectedStatus : "Successful" }}</span
                >
              </template>
              <b-dropdown-item @click="selectedStatus = 'All'"
                >All</b-dropdown-item
              >
              <b-dropdown-item @click="selectedStatus = ''"
                >Successful</b-dropdown-item
              >
              <b-dropdown-item @click="selectedStatus = 'Pending'"
                >Pending</b-dropdown-item
              >
              <b-dropdown-item @click="selectedStatus = 'Failed'"
                >Failed</b-dropdown-item
              >
            </b-dropdown>
          </b-button-toolbar>
        </b-col>
      </b-row> -->
      <b-row no-gutters class="mt-3 mb-2" align-v="center">
        <b-col>
          <header class="w-max-content">
            <h6 class="mb-0">All Transactions</h6>
            <label class="small font-weight-bold">
              {{ filters.dates.from | date }} / {{ filters.dates.to | date }}
            </label>
          </header>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="dark"
            size="sm"
            class="mx-1"
            v-b-modal="'transactions-filters'"
          >
            <b-icon-gear-fill /> Filters
          </b-button>
          <b-button
            @click="refresh"
            :disabled="state.loading"
            variant="dark"
            size="sm"
            class="mx-1"
          >
            Refresh
          </b-button>
        </b-col>
      </b-row>
      <b-row no-gutters align-h="center">
        <transition name="component-fade" mode="out-in">
          <Line-Loader v-if="state.loading" />
        </transition>
        <transition-group
          name="component-group-fade"
          mode="out-in"
          class="w-100"
        >
          <b-container
            class="p-5 py-0 bg-light br-5"
            key="1"
            v-if="shownTransactions.length < 1"
          >
            <transition-group name="component-group-fade" mode="out-in">
              <p class="text-center" key="1" v-show="state.loading">
                Loading transactions...
              </p>
              <p class="text-center" key="2" v-show="!state.loading">
                {{
                  state.message ||
                  "You don't have any transactions at the moment."
                }}
              </p>
            </transition-group>
          </b-container>
          <b-container key="2" v-else>
            <transactions-table :transactions="shownTransactions" />
            <b-pagination
              size="sm"
              @input="pageChanged"
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRows"
              :per-page="pagination.perPage"
              pills
              align="center"
              v-if="showPagination"
            ></b-pagination>
            <trans-totals
              v-if="totals && shownTransactions.length > 0"
              :totals="totals"
            />
          </b-container>
        </transition-group>
      </b-row>
    </b-container>
    <b-modal
      size="sm"
      hide-header
      body-class="px-1"
      id="transactions-filters"
      centered
      header-bg-variant="light"
      hide-footer
      no-close-on-backdrop
    >
      <trans-filters @filtered="filterTransactions" :filters="filters" />
    </b-modal>
  </section>
</template>

<script>
import _filter from "lodash/filter";
import _orderBy from "lodash/orderBy";
import moment from "moment";
import {
  balancePill,
  transactionsFilters,
  transactionsTotals,
} from "../../components";
import TransactionsTable from "./transactions-table.vue";
export default {
  name: "merchant-transactions",
  components: {
    TransactionsTable,
    balancePill,
    "trans-totals": transactionsTotals,
    "trans-filters": transactionsFilters,
  },
  data() {
    let dates = {
      from: moment().subtract(1, "months").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
    };
    return {
      state: { loading: false, message: null },
      allTransactions: [],
      selectedKind: null,
      selectedStatus: "All",
      totals: null,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 25,
        offset: 0,
      },
      filters: {
        client: null,
        dates,
      },
    };
  },
  mounted() {
    this.fetchTransactions();
  },
  filters: {
    date: (date) => {
      if (!moment.isDate(new Date(date))) return date;
      return moment(new Date(date)).format("DD-MM-YYYY");
    },
  },
  computed: {
    shownTransactions() {
      const withKind = this.selectedKind == null ? false : true;
      const withStatus =
        this.selectedStatus.toLowerCase() == "all" ? false : true;
      const toFilter = {};
      if (withKind) toFilter.kind = this.selectedKind;
      if (withStatus) toFilter.status = this.selectedStatus;

      return _orderBy(
        _filter(this.allTransactions, toFilter),
        ["timestamp"],
        ["desc"]
      );
    },
    showPagination() {
      return this.pagination.totalRows > this.pagination.perPage;
    },
    today() {
      return moment();
    },
  },
  watch: {
    selectedKind() {
      this.$nextTick(() => {
        this.$set(this, "allTransactions", []);
        this.resetPagination();
        this.refresh();
      });
    },
  },
  methods: {
    async fetchTransactions() {
      if (this.state.loading) return;
      this.state.message = null;
      this.state.loading = true;
      const startOfDay = (date) =>
        moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
      const endOfDay = (date) =>
        moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
      const reqData = {
        offset: this.pagination.offset,
        limit: this.pagination.perPage,
        kind: this.selectedKind,
        from: startOfDay(this.filters.dates.from),
        to: endOfDay(this.filters.dates.to),
        client: this.filters.client,
      };
      const queryString = this.$getQueryString(reqData);
      try {
        const { data } = await this.axios(`transactions/list?${queryString}`);
        if (data && Array.isArray(data.transactions)) {
          if (data.transactions.length < 1) {
            const fd = (date) => moment(date).format("DD-MM-YYYY");
            this.state.message = `No Transactions found from ${fd(
              this.filters.dates.from
            )} to ${fd(this.filters.dates.to)}`;
          }
          this.$set(this.pagination, "totalRows", data.total);
          this.$set(this, "allTransactions", data.transactions);
          this.$set(this, "totals", {
            cashin: data.cashin,
            cashout: data.cashout,
            fee: data.fee,
            total: data.cashin - data.cashout - data.fee,
          });
        }
      } catch (error) {
       if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      }
      this.state.loading = false;
    },
    refresh() {
      this.fetchTransactions();
    },
    filterTransactions(params) {
      this.$nextTick(() => {
        this.$set(this.filters.dates, "from", params.from);
        this.$set(this.filters.dates, "to", params.to);
        this.$set(this.filters, "client", params.client);
        this.resetPagination();
        this.refresh();
      });
    },
    pageChanged(page) {
      const { perPage } = this.pagination;
      this.$nextTick(() => {
        this.$set(this.pagination, "offset", perPage * (page - 1));
        this.$set(this.pagination, "currentPage", page);
        this.refresh();
      });
    },
    resetPage() {
      this.$nextTick(() => {
        this.$set(this.filters.dates, "from", null);
        this.$set(this.filters.dates, "to", null);
        this.$set(this.filters, "client", null);
        this.$set(this, "totals", null);
        this.resetPagination();
      });
    },
    resetPagination() {
      this.$nextTick(() => {
        this.$set(this.pagination, "totalRows", 0);
        this.$set(this.pagination, "offset", 0);
        this.$set(this.pagination, "currentPage", 1);
      });
    },
  },
};
</script>

<style>
</style>