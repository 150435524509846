<template>
  <b-row>
    <b-table
      :items="transactions"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      small
      responsive
      thead-class="bg-light br-10 text-right"
      table-class="font-15 "
      hover
    >
      <template #cell(status)="row">
        <b-badge
          :variant="statusBadgeVariantHandler(row.value)"
          class="py-1 px-2"
          >{{ row.value || "successful" }}</b-badge
        >
      </template>
      <template #cell(amount)="row"
        >{{ row.value | money }}{{ " Rwf" }}</template
      >
      <template #cell(fee)="row">{{ row.value | money }}{{ " Rwf" }}</template>
      <template #cell(balance)="row">
        <span class="d-block text-right"
          >{{ balanceCalc(row.item) | money }}{{ " Rwf" }}</span
        >
      </template>
      <template #cell(timestamp)="row">
        <span
          class="d-block"
          v-b-tooltip.hover.noninteractive
          :title="row.value | fullDate"
          >{{ row.value | date }}</span
        >
      </template>
      <template #cell(No)="row">
        <span class="px-1">{{ row.index + 1 }}</span>
      </template>
    </b-table>
  </b-row>
</template>

<script>
import moment from "moment";
export default {
  props: ["transactions", "limit"],
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: ["client"],
      fields: [
        "No",
        {
          key: "client",
          label: "Client",
          sortable: true,
          thClass: "text-left",
        },
        {
          key: "kind",
          label: "Kind",
          sortable: true,
          tdClass: "text-right pl-3 pr-4",
          thClass: "text-right pl-3 pr-4",
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "amount",
          label: "Amount",
          sortable: true,
          tdClass: "text-right px-3",
          thClass: "text-right px-4",
        },
        {
          key: "fee",
          label: "Fee",
          sortable: true,
          tdClass: "text-right px-3",
          thClass: "text-center px-4",
        },
        "Balance",
        {
          key: "timestamp",
          label: "Date",
          sortable: true,
          tdClass: "text-left pl-3 pr-2",
          thClass: "text-center px-4",
        },
      ],
    };
  },
  filters: {
    money: (num) => Number(num).toLocaleString('en-US'),
    date: (date) => {
      if (!moment.isDate(new Date(date))) return "";
      return moment(new Date(date)).format("DD-MM-YY, H:mm");
    },
    fullDate: (date) => {
      if (!moment.isDate(new Date(date))) return "";
      return moment(new Date(date)).format("Do MMMM YYYY, hh:mm A");
    },
  },

  mounted() {},
  methods: {
    statusBadgeVariantHandler(status) {
      switch (status) {
        case "successfull":
          return "success";
        case "failed":
          return "danger";
        case "pending":
          return "warning";
        default:
          return "success";
      }
    },
    balanceCalc(item) {
      if (item.kind == "CASHIN") {
        return item.amount - item.fee;
      } else if (item.kind == "CASHOUT") {
        return item.amount + item.fee;
      } else {
        return item.amount;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.totals-box {
  min-width: max-content;
  font-weight: 600;
}
</style>