import Vue from "vue";
import Vuex from "vuex";

import requests from "../helpers/requests.js";

import admin from "../dashboards/admin/adminStore";
const initialState = {
  cancelTokens: [],
  balance: null,
  user: null,
  loadingProfile: false,
};
Vue.use(Vuex);
export default new Vuex.Store({
  state: initialState,
  mutations: {
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      if (state.cancelTokens.length < 1) return;
      state.cancelTokens = [];
    },
    SET_BALANCE(state, data) {
      if (!data) return;
      if (data.balance) {
        state.balance = data.balance;
      }
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_PROFILE_LOADING(state, status) {
      state.loadingProfile = status;
    },
    CLEAR_DATA(state) {
      state = initialState;
    },
  },
  actions: {
    CANCEL_PENDING_REQUESTS(context) {
      // Cancel all request where a token exists
      context.state.cancelTokens.forEach((request, i) => {
        if (request.cancel) {
          request.cancel();
        }
      });
      // Reset the cancelTokens store
      context.commit("CLEAR_CANCEL_TOKENS");
    },
    async REFRESH_PROFILE(context) {
      if (context.state.loadingProfile) return;
      try {
        context.commit("SET_PROFILE_LOADING", true);
        const data = await requests.profile();
        context.commit("SET_BALANCE", data);
        context.commit("SET_USER", data);
      } finally {
        context.commit("SET_PROFILE_LOADING", false);
      }
    },
    CLEAR_DATA(context) {
      context.commit("CLEAR_DATA");
    },
  },
  getters: {
    cancelTokens(state) {
      return state.cancelTokens;
    },
  },
  modules: { admin },
});
