<template>
  <b-container class="h-100 d-flex flex-column">
    <b-row align-h="end" align-v="end">
      <b-form inline @submit.prevent="refresh">
        <b-dropdown size="sm" variant="white" class="m-1" toggle-class="border" right>
          <template #button-content>
            <span class="m-1 text-uppercase">
              Provider:
              {{ selectedProvider ? selectedProvider : "All" }}</span
            >
          </template>
          <b-dropdown-item @click="selectedProvider = 'mtn'">
            MTN
          </b-dropdown-item>
          <b-dropdown-item @click="selectedProvider = 'airtel'">
            AIRTEL
          </b-dropdown-item>
        </b-dropdown>
        <b-form-group>
          <b-form-datepicker
            class="m-1"
            size="sm"
            placeholder="From"
            hide-header
            v-model="filters.dates.from"
            :max="today"
            :date-format-options="{
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }"
          />
        </b-form-group>
        <b-form-group>
          <b-form-datepicker
            class="m-1"
            size="sm"
            placeholder="To"
            hide-header
            v-model="filters.dates.to"
            :min="filters.dates.from"
            :max="today"
            required
            :date-format-options="{
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }"
          />
        </b-form-group>
        <b-form-group>
          <b-button class="m-1" type="submit" variant="dark" size="sm">
            Refresh
          </b-button>
        </b-form-group>
      </b-form>
    </b-row>
    <b-row align-h="center" class="mt-2 h-100">
      <transition-group name="component-group-fade" mode="out-in" class="w-100">
        <b-container key="2" class="px-0">
          <b-row>
            <b-col cols="12" lg="6">
              <b-container
                fluid
                class="border p-2 my-3 mx-1 rounded chart d-flex flex-column"
              >
                <b-row no-gutters class="mb-3">
                  <h6 class="mb-0 font-15">Transaction Rate</h6>
                </b-row>
                <b-row
                  no-gutters
                  class="h-100 overflow-hidden"
                  align-v="center"
                  align-h="center"
                >
                  <transaction-rate
                    class="position-relative w-100 h-100"
                    v-if="charts.transactionRate.loaded"
                    :chart-data="charts.transactionRate.data"
                    :chart-labels="charts.transactionRate.labels"
                  />
                  <b-spinner v-else></b-spinner>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="12" lg="6">
              <b-container
                fluid
                class="border p-2 my-3 mx-1 rounded chart d-flex flex-column"
              >
                <b-row no-gutters class="mb-3">
                  <h6 class="mb-0 font-15">Total Fees</h6>
                </b-row>
                <b-row
                  no-gutters
                  class="h-100 overflow-hidden"
                  align-v="center"
                  align-h="center"
                >
                  <transaction-rate
                    class="position-relative w-100 h-100"
                    v-if="charts.fees.loaded"
                    :chart-data="charts.fees.data"
                    :chart-labels="charts.fees.labels"
                  />
                  <b-spinner v-else></b-spinner>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="12" lg="6">
              <b-container
                fluid
                class="border p-2 my-3 mx-1 rounded chart d-flex flex-column"
              >
                <b-row no-gutters class="mb-3">
                  <h6 class="mb-0 font-15">Cash-in Amount</h6>
                </b-row>
                <b-row
                  no-gutters
                  class="h-100 overflow-hidden"
                  align-v="center"
                  align-h="center"
                >
                  <transaction-rate
                    class="position-relative w-100 h-100"
                    v-if="charts.cashinAmount.loaded"
                    :chart-data="charts.cashinAmount.data"
                    :chart-labels="charts.cashinAmount.labels"
                  />
                  <b-spinner v-else></b-spinner>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="12" lg="6">
              <b-container
                fluid
                class="border p-2 my-3 mx-1 rounded chart d-flex flex-column"
              >
                <b-row no-gutters class="mb-3">
                  <h6 class="mb-0 font-15">Cash-out Amount</h6>
                </b-row>
                <b-row
                  no-gutters
                  class="h-100 overflow-hidden"
                  align-v="center"
                  align-h="center"
                >
                  <transaction-rate
                    class="position-relative w-100 h-100"
                    v-if="charts.cashoutAmount.loaded"
                    :chart-data="charts.cashoutAmount.data"
                    :chart-labels="charts.cashoutAmount.labels"
                  />
                  <b-spinner v-else></b-spinner>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="12">
              <b-container
                fluid
                class="border p-2 my-3 mx-1 rounded chart d-flex flex-column"
              >
                <b-row no-gutters class="mb-3">
                  <h6 class="mb-0 font-15">Total Daily Amount</h6>
                </b-row>
                <b-row
                  no-gutters
                  class="h-100 overflow-hidden"
                  align-v="center"
                  align-h="center"
                >
                  <transaction-rate
                    class="position-relative w-100 h-100"
                    v-if="charts.totalDailyAmount.loaded"
                    :chart-data="charts.totalDailyAmount.data"
                    :chart-labels="charts.totalDailyAmount.labels"
                  />
                  <b-spinner v-else></b-spinner>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="12">
              <b-container
                fluid
                class="border p-2 my-3 mx-1 rounded chart d-flex flex-column"
              >
                <b-row no-gutters class="mb-3">
                  <h6 class="mb-0 font-15">Paypack Fees</h6>
                </b-row>
                <b-row
                  no-gutters
                  class="h-100 overflow-hidden"
                  align-v="center"
                  align-h="center"
                >
                  <transaction-rate
                    class="position-relative w-100 h-100"
                    v-if="charts.paypackFees.loaded"
                    :chart-data="charts.paypackFees.data"
                    :chart-labels="charts.paypackFees.labels"
                  />
                  <b-spinner v-else></b-spinner>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-container>
      </transition-group>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import _orderBy from "lodash/orderBy";
import { transactionRate } from "../../components/charts";
export default {
  props: ["merchant"],
  components: { transactionRate },
  data() {
    let dates = {
      from: moment().subtract(1, "months").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
    };
    return {
      i: 0,
      state: { loading: false },
      from: dates.from,
      to: dates.to,
      selectedProvider: "mtn",
      allTransactions: [],
      filters: {
        client: null,
        dates,
      },
      charts: {
        transactionRate: {
          loaded: false,
          errorMessage: null,
          labels: new Array(),
          data: new Array(),
        },
        cashinAmount: {
          loaded: false,
          errorMessage: null,
          labels: new Array(),
          data: new Array(),
        },
        cashoutAmount: {
          loaded: false,
          errorMessage: null,
          labels: new Array(),
          data: new Array(),
        },
        fees: {
          loaded: false,
          errorMessage: null,
          labels: new Array(),
          data: new Array(),
        },
        totalDailyAmount: {
          loaded: false,
          errorMessage: null,
          labels: new Array(),
          data: new Array(),
        },
        paypackFees: {
          loaded: false,
          errorMessage: null,
          labels: new Array(),
          data: new Array(),
        },
      },
    };
  },
  computed: {
    today() {
      return moment().toDate();
    },
  },
  watch: {
    allTransactions(transactions) {
      const formatDate = (date) => {
        if (!moment.isDate(new Date(date))) return date;
        return moment(new Date(date)).format("DD-MM-YYYY");
      };

      if (Array.isArray(transactions)) {
        if (transactions.length > 0) {
          transactions.forEach((tranx) => {
            this.charts.transactionRate.labels.push(formatDate(tranx.date));
            this.charts.transactionRate.data.push(tranx.total);

            this.charts.cashinAmount.labels.push(formatDate(tranx.date));
            this.charts.cashinAmount.data.push(tranx.cashin_amount);

            this.charts.cashoutAmount.labels.push(formatDate(tranx.date));
            this.charts.cashoutAmount.data.push(tranx.cashout_amount);

            this.charts.fees.labels.push(formatDate(tranx.date));
            this.charts.fees.data.push(tranx.cashin_fees + tranx.cashout_fees);

            this.charts.paypackFees.labels.push(formatDate(tranx.date));
            this.charts.paypackFees.data.push(tranx.total_amount * 0.007);

            this.charts.totalDailyAmount.labels.push(formatDate(tranx.date));
            this.charts.totalDailyAmount.data.push(tranx.total_amount);
          });
          this.charts.transactionRate.loaded = true;
          this.charts.cashinAmount.loaded = true;
          this.charts.cashoutAmount.loaded = true;
          this.charts.fees.loaded = true;
          this.charts.totalDailyAmount.loaded = true;
          this.charts.paypackFees.loaded = true;
        }
      }
    },
    selectedProvider() {
      this.fetchTransactions();
    },
  },
  filters: {
    date: (date) => {
      if (!moment.isDate(new Date(date))) return date;
      return moment(new Date(date)).format("DD-MM-YYYY");
    },
    currency: (num) => Number(num).toLocaleString('en-US'),
  },
  async mounted() {
    this.fetchTransactions();
  },
  methods: {
    async fetchTransactions() {
      if (this.state.loading) return;
      this.state.loading = true;
      this.setChart("transactionRate");
      this.setChart("cashinAmount");
      this.setChart("cashoutAmount");
      this.setChart("fees");
      this.setChart("totalDailyAmount");
      this.setChart("paypackFees");
      try {
        const startOfDay = (date) =>
          moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
        const endOfDay = (date) =>
          moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");

        const reqData = {
          from: startOfDay(this.filters.dates.from),
          to: endOfDay(this.filters.dates.to),
          provider: this.selectedProvider,
        };

        const queryString = this.$getQueryString(reqData);

        const { data } = await this.axios(
          `internal/reports/daily?${queryString}`
        );

        if (Array.isArray(data.transactions)) {
          this.$set(
            this,
            "allTransactions",
            _orderBy(
              data.transactions,
              [
                "data.transactions",
                function (o) {
                  return o.date;
                },
              ],
              ["asc"],
              null,
              4
            )
          );
        }
      } catch (error) {
       if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.loading = false;
      }
    },
    refresh() {
      this.fetchTransactions();
    },
    setChart(
      chartKey,
      loaded = false,
      labels = new Array(),
      data = new Array(),
      errorMessage = null
    ) {
      if (!chartKey) return;
      if (!this.charts[chartKey]) return;
      this.$set(this.charts[chartKey], "loaded", loaded);
      this.charts[chartKey].labels = labels;
      this.charts[chartKey].data = data;
      this.charts[chartKey].errorMessage = errorMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 250px;
}
</style>
