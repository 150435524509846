<template>
  <section id="request">
    <header>
      <b-row no-gutters align-v="center">
        <b-col class="mb-1">
          <header class="mb-2 d-flex flex-nowrap">
            <b-button
              class="mr-2"
              size="sm"
              :to="{ name: 'admin-verifications' }"
              variant="dark"
              >Back</b-button
            >
            <h5 class="mb-0 font-weight-bold">Account Request</h5>
          </header>
        </b-col>
      </b-row>
    </header>

    <b-container
      class="p-5 py-0 bg-light br-5 flex-center flex-column"
      v-if="state.loading"
    >
      <span><b-spinner small class="mb-2" /></span>
      <p class="text-center">Loading verification request...</p>
    </b-container>

    <b-container class="mt-4 request-details" v-else>
      <div v-if="request">
        <h6 class="mt-4 border-bottom pb-2">Identification</h6>
        <b-row class="mb-4" align-v="center">
          <b-col class="py-2 pr-3" cols="12" md="6">
            <legend>National ID</legend>
            <div class="previews d-flex flex-wrap">
              <div
                class="
                  preview-box
                  rounded
                  bg-light
                  flex-center
                  p-1
                  position-relative
                "
                v-for="(link, i) in request.national_id"
                :key="i"
              >
                <b-link :href="link" target="_blank">
                  <b-icon-file-earmark-binary-fill
                    variant="dark"
                    font-scale="1.75"
                  />
                </b-link>
              </div>
            </div>
          </b-col>

          <b-col class="py-2 pr-3" cols="12" md="6">
            <legend>Certificates</legend>
            <div class="previews d-flex flex-wrap">
              <div
                class="
                  preview-box
                  rounded
                  bg-light
                  flex-center
                  p-1
                  position-relative
                "
                v-for="(link, i) in request.certificates"
                :key="i"
              >
                <b-link :href="link" target="_blank">
                  <b-icon-file-earmark-binary-fill
                    variant="dark"
                    font-scale="1.75"
                  />
                </b-link>
              </div>
            </div>
          </b-col>
        </b-row>

        <h6 class="mt-4 border-bottom pb-2">Banking</h6>
        <b-row class="mb-4" align-v="center">
          <b-col class="py-2 pr-3" cols="12" md="6">
            <legend>Bank Account</legend>
            <p class="cursor-text">{{ request.bank_account }}</p>
          </b-col>
          <b-col class="py-2 pr-3" cols="12" md="6">
            <legend>Bank Name</legend>
            <p class="cursor-text">{{ request.bank_name }}</p>
          </b-col>
          <b-col class="pt-3 pr-3" cols="12">
            <legend>Verification Purpose</legend>
            <p class="cursor-text">{{ request.purpose }}</p>
          </b-col>
        </b-row>
        <b-form @submit.prevent="approveUser">
          <b-row class="mb-4" align-v="center">
            <b-col class="py-2 pr-3" cols="12">
              <legend>Decision</legend>
              <b-form-group>
                <b-select v-model="status" required>
                  <b-select-option value="approved" class="font-weight-normal"
                    >Approve</b-select-option
                  >
                  <b-select-option value="rejected" class="font-weight-normal"
                    >Reject</b-select-option
                  >
                </b-select>
              </b-form-group>
            </b-col>

            <b-col class="py-2 pr-3" cols="12" v-if="status == 'rejected'">
              <legend>Rejection Message</legend>
              <b-form-group>
                <b-textarea rows="5" v-model="status_message" trim required />
              </b-form-group>
            </b-col>
            <b-col class="py-2 pr-3" cols="12">
              <b-row no-gutters>
                <b-button
                  type="submit"
                  variant="dark"
                  class="has-loading"
                  :disabled="state.submitting"
                >
                  <b-container v-if="state.submitting">
                    <b-spinner />
                  </b-container>
                  <span>Submit</span>
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "merchant-verification-request",
  props: ["id"],
  data() {
    return {
      state: { loading: true, submitting: false },
      status: "",
      status_message: "",
      request: null,
    };
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    async fetchUser() {
      this.state.loading = true;
      try {
        const { data } = await this.axios(
          `internal/account-verification/find/${this.id}`
        );
        if (data) {
          this.$set(this, "request", data);
        }
      } catch (error) {
        if (error.errorMessage.includes("verification request not found"))
          return (this.account_status = null);
      if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.loading = false;
      }
    },
    async approveUser() {
      if (this.state.submitting) return;
      this.state.submitting = true;
      try {
        const reqData = {
          status: this.status,
          status_message: this.status_message,
        };
        const { data } = await this.axios.post(
          `internal/account-verification/verify/${this.request.id}`,
          reqData
        );

        if (data) {
          this.$notify(`Account ${this.status}.`, "success");
          this.$router.push({ name: "admin-verifications" });
        }
      } catch (error) {
        if (error.errorMessage.includes("verification request not found"))
          return (this.account_status = {});
        if (error.errorMessage) {
          if (Array.isArray(error.errorMessage))
            this.$notify(error.errorMessage[0], "error");
          else this.$notify(error.errorMessage, "error");
        } else {
          this.$notify("Something went wrong", "error");
        }
      } finally {
        this.state.submitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#request {
  .request-details {
    legend {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 3px;
    }
    p {
      font-size: 15px;
      margin-bottom: 0px;
    }
  }
  .verification-notice {
    font-size: 15.5px;
    line-height: 160%;
  }

  h6 {
    font-weight: 700;
  }
  .previews {
    margin: -5px;
    .preview-box {
      max-width: 40px;
      height: 40px;
      overflow: hidden;
      aspect-ratio: 1;
      cursor: pointer;
      margin: 5px;
      transition: all 250ms ease;

      &:hover {
        transform: scale(1.07);
      }
    }
  }
}
</style>